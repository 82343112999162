import { apiGet, apiPost } from '../../utils';

export function getAllOrdersAPI(data) {
    // const { limit = 10, skip = 0, searchTerm = '', sortBy = 'sale', categoryId = '', productTags = '', brandId = '', active = true } = data || {}
    // const newData = { limit, skip, searchTerm, sortBy, categoryId, productTags, brandId, active }
    return apiGet('api/v1/cart/getorders', data);
}

export function getFhlOrderAPI(data) {
    return apiGet('api/v1/fhl/get/order/limit',data);
}

export function updateFhlOrderAPI(data) {
    return apiPost(`api/v1/fhl/update/order/limit`,data);
}