import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom"
import { Button, Typography, FormGroup, Grid, FormLabel } from '@material-ui/core';
import * as promotionsActions from "../../redux/actions/promotions"
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../constants';

import CustomInput from '../../components/common/CustomInput';
import CustomDropDown from '../../components/common/CustomDropDown';
import CustomCheckbox from '../../components/common/CustomCheckbox';
import { validatePromotionData } from '../../utilities/validations/promotion';

function EditPromotions(props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch()

    const { modalData } = useSelector(state => state.common)

    const { title = "Edit", data: editData = {} } = modalData

    const [data, setPromotionData] = useState({})
    const [promotionImages, setPromotionImages] = useState("")
    const [errors, setErrors] = useState("")

    useEffect(() => {
        if (editData) {
            setPromotionData({ ...editData })
            setPromotionImages((editData.images && editData.images[0] && `${BASE_URL}${editData.images[0]}`) || '')
        }
    }, [])

    const handleChangePromotion = (e) => {
        const { name, value } = e.target

        setPromotionData({ ...data, [name]: value })
        setErrors({ ...errors, [name]: "" })
    }

    const editPromotion = () => {
        const { title, description, status, id } = data;

        let updatedData = { title, description, status, id }

        if (promotionImages) {
            updatedData = {
                ...updatedData,
                images: promotionImages
            }
        }

        const { errors, isValid } = validatePromotionData(updatedData)
        setErrors(errors);

        if (isValid) {
            dispatch(promotionsActions.updatePromotion(updatedData)).then(() => {
                dispatch(promotionsActions.getAllPromotion())
                props.closeModal()
            }).catch(() => { })
        }

    }

    const onChangeStatus = (val) => {
        let updatedData = { ...data }

        updatedData = {
            ...updatedData,
            status: val
        }

        setPromotionData(updatedData)
    }

    const handleChangeImages = (files = []) => {
        let image = files[0]
        setPromotionImages(image)
    }

    return (
        <Grid container component="main" className={`${classes.root} add-category-modal-container`}>

            <Grid item xs={12} className="pt-2">
                <Typography variant="h4" className="d-flex jc-ctr">{title} Promotion</Typography>
            </Grid>
            <Grid item xs={12} className="add-category-input">
                <CustomDropDown
                    setFile={(v) => { handleChangeImages(v) }}
                    src={promotionImages && typeof promotionImages == "string" ? promotionImages : promotionImages && URL.createObjectURL(promotionImages) || ""}
                    imageProps={{ height: "200px" }}
                    accept=".jpeg,.jpg,.png,.gif"
                />
                <CustomInput
                    name="title"
                    value={data.title || ""}
                    onChange={(e) => { handleChangePromotion(e) }}
                    label="Title"
                    className="w-100"
                    error={errors.title}
                />
                <CustomInput
                    name="description"
                    value={data.description || ""}
                    onChange={(e) => { handleChangePromotion(e) }}
                    label="Description"
                    className="w-100"
                    margin="normal"
                />
                <FormGroup row className="d-block">
                    <FormLabel component="legend">Status</FormLabel>
                    <div>
                        <CustomCheckbox
                            label="Active"
                            onChange={e => onChangeStatus(e.target.checked)}
                            value={data.status || false}
                        />
                        <CustomCheckbox
                            label="InActive"
                            onChange={e => onChangeStatus(!e.target.checked)}
                            value={!data.status || false}
                        />
                    </div>
                </FormGroup>
            </Grid>
            <Grid item xs={12} className="d-flex jc-ctr pb-2">
                <Button onClick={() => { editPromotion() }} variant="contained" color="primary" fullWidth>Submit</Button>
            </Grid>
        </Grid >
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));


export default EditPromotions