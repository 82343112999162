import {
    addCategoryAPI,
    getAllBlazeCategoryAPI,
    getAllCategoryAPI,
    getAllProductsAPI,
    getInfoAndEffectAPI,
    getSingleProductDetailsAPI,
    updateCategoriesAPI,
    updateSingleProductAPI,
    deleteCategoryAPI,
    getAllCategoryImagesAPI,
    getAllBrandsByProductAPI,
    getAllBmwBrandsAPI,
    addBrandToBmwAPI,
    updateBrandFromBmwAPI,
    deleteBrandFromBmwAPI,
    syncProductAdminAPI,
    getThresholdAdminAPI,
    updateThresholdAPI
} from "../api/products";

import { toast } from 'react-toastify';

export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const GET_ALL_PRODUCTS_SUCCESSFULL = 'GET_ALL_PRODUCTS_SUCCESSFULL';
export const GET_ALL_PRODUCTS_FAILED = 'GET_ALL_PRODUCTS_FAILED';

export function getAllProducts(data) {
    return dispatch => {
        dispatch({
            type: GET_ALL_PRODUCTS
        })
        return new Promise((resolve, reject) => {
            getAllProductsAPI(data)
                .then((res) => {
                    //User Logged in sucessfully 
                    dispatch({
                        type: GET_ALL_PRODUCTS_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_ALL_PRODUCTS_FAILED
                    })
                    return reject(err)
                })
        })
    }
}


export const GET_SINGLE_PRODUCT_DETAILS = 'GET_SINGLE_PRODUCT_DETAILS';
export const GET_SINGLE_PRODUCT_DETAILS_SUCCESSFULL = 'GET_SINGLE_PRODUCT_DETAILS_SUCCESSFULL';
export const GET_SINGLE_PRODUCT_DETAILS_FAILED = 'GET_SINGLE_PRODUCT_DETAILS_FAILED';

export function getSingleProductDetails(data) {
    return dispatch => {
        dispatch({
            type: GET_SINGLE_PRODUCT_DETAILS
        })
        return new Promise((resolve, reject) => {
            getSingleProductDetailsAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_SINGLE_PRODUCT_DETAILS_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_SINGLE_PRODUCT_DETAILS_FAILED
                    })
                    return reject(err)
                })
        })
    }
}


export const GET_INFO_AND_EFFECT = 'GET_INFO_AND_EFFECT';
export const GET_INFO_AND_EFFECT_SUCCESSFULL = 'GET_INFO_AND_EFFECT_SUCCESSFULL';
export const GET_INFO_AND_EFFECT_FAILED = 'GET_INFO_AND_EFFECT_FAILED';

export function getInfoAndEffect(data) {
    return dispatch => {
        dispatch({
            type: GET_INFO_AND_EFFECT
        })
        return new Promise((resolve, reject) => {
            getInfoAndEffectAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_INFO_AND_EFFECT_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_INFO_AND_EFFECT_FAILED
                    })
                    return reject(err)
                })
        })
    }
}



export const UPDATE_SINGLE_PRODUCT = 'UPDATE_SINGLE_PRODUCT';
export const UPDATE_SINGLE_PRODUCT_SUCCESSFULL = 'UPDATE_SINGLE_PRODUCT_SUCCESSFULL';
export const UPDATE_SINGLE_PRODUCT_FAILED = 'UPDATE_SINGLE_PRODUCT_FAILED';

export function updateSingleProduct(id, data) {
    return dispatch => {
        dispatch({
            type: UPDATE_SINGLE_PRODUCT
        })
        return new Promise((resolve, reject) => {
            updateSingleProductAPI(id, data)
                .then((res) => {
                    dispatch({
                        type: UPDATE_SINGLE_PRODUCT_SUCCESSFULL,
                    })
                    return resolve(res)
                })
                .catch(err => {
                    toast.error(err)
                    dispatch({
                        type: UPDATE_SINGLE_PRODUCT_FAILED
                    })
                    return reject(err)
                })
        })
    }
}




export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_CATEGORY_SUCCESSFULL = 'ADD_CATEGORY_SUCCESSFULL';
export const ADD_CATEGORY_FAILED = 'ADD_CATEGORY_FAILED';

export function addCategory(data, id) {
    return dispatch => {
        dispatch({
            type: ADD_CATEGORY
        })
        return new Promise((resolve, reject) => {
            addCategoryAPI(data, id)
                .then((res) => {
                    dispatch({
                        type: ADD_CATEGORY_SUCCESSFULL,
                        data: res
                    })
                    let txt = "Added"
                    if (id) {
                        txt = "Updated"
                    }
                    toast.success(`Category ${txt} successfully.`)
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: ADD_CATEGORY_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const GET_ALL_CATEGORY = 'GET_ALL_CATEGORY';
export const GET_ALL_CATEGORY_SUCCESSFULL = 'GET_ALL_CATEGORY_SUCCESSFULL';
export const GET_ALL_CATEGORY_FAILED = 'GET_ALL_CATEGORY_FAILED';

export function getAllCategory(data) {
    return dispatch => {
        dispatch({
            type: GET_ALL_CATEGORY
        })
        return new Promise((resolve, reject) => {
            getAllCategoryAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_ALL_CATEGORY_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_ALL_CATEGORY_FAILED
                    })
                    return reject(err)
                })
        })
    }
}

export const GET_ALL_BLAZE_CATEGORY = 'GET_ALL_BLAZE_CATEGORY';
export const GET_ALL_BLAZE_CATEGORY_SUCCESSFULL = 'GET_ALL_BLAZE_CATEGORY_SUCCESSFULL';
export const GET_ALL_BLAZE_CATEGORY_FAILED = 'GET_ALL_BLAZE_CATEGORY_FAILED';

export function getAllBlazeCategory(data) {
    return dispatch => {
        dispatch({
            type: GET_ALL_BLAZE_CATEGORY
        })
        return new Promise((resolve, reject) => {
            getAllBlazeCategoryAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_ALL_BLAZE_CATEGORY_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_ALL_BLAZE_CATEGORY_FAILED
                    })
                    return reject(err)
                })
        })
    }
}


export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
export const UPDATE_CATEGORIES_SUCCESSFULL = 'UPDATE_CATEGORIES_SUCCESSFULL';
export const UPDATE_CATEGORIES_FAILED = 'UPDATE_CATEGORIES_FAILED';

export function updateCategories(data) {
    return dispatch => {
        dispatch({
            type: UPDATE_CATEGORIES
        })
        return new Promise((resolve, reject) => {
            updateCategoriesAPI(data)
                .then((res) => {
                    dispatch({
                        type: UPDATE_CATEGORIES_SUCCESSFULL,
                        data: res
                    })
                    toast.success(res.message)
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_CATEGORIES_FAILED
                    })
                    toast.success(err || 'Something went wrong')
                    return reject(err)
                })
        })
    }
}

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESSFULL = 'DELETE_CATEGORY_SUCCESSFULL';
export const DELETE_CATEGORY_FAILED = 'DELETE_CATEGORY_FAILED';

export function deleteCategory(data) {
    return dispatch => {
        dispatch({
            type: DELETE_CATEGORY
        })
        return new Promise((resolve, reject) => {
            deleteCategoryAPI(data)
                .then((res) => {
                    dispatch({
                        type: DELETE_CATEGORY_SUCCESSFULL,
                        data: res
                    })
                    toast.success("Category Deleted successfully.")
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: DELETE_CATEGORY_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}


export const CATEGORY_IMAGES = 'CATEGORY_IMAGES';
export const CATEGORY_IMAGES_SUCCESSFULL = 'CATEGORY_IMAGES_SUCCESSFULL';
export const CATEGORY_IMAGES_FAILED = 'CATEGORY_IMAGES_FAILED';

export function getAllCategoryImages(data) {
    return dispatch => {
        dispatch({
            type: CATEGORY_IMAGES
        })
        return new Promise((resolve, reject) => {
            getAllCategoryImagesAPI(data)
                .then((res) => {
                    dispatch({
                        type: CATEGORY_IMAGES_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: CATEGORY_IMAGES_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const ALL_BRANDS_BY_PRODUCT = 'ALL_BRANDS_BY_PRODUCT';
export const ALL_BRANDS_BY_PRODUCT_SUCCESSFULL = 'ALL_BRANDS_BY_PRODUCT_SUCCESSFULL';
export const ALL_BRANDS_BY_PRODUCT_FAILED = 'ALL_BRANDS_BY_PRODUCT_FAILED';

export function getAllBrandsByProducts(data) {
    return dispatch => {
        dispatch({
            type: ALL_BRANDS_BY_PRODUCT
        })
        return new Promise((resolve, reject) => {
            getAllBrandsByProductAPI(data)
                .then((res) => {
                    dispatch({
                        type: ALL_BRANDS_BY_PRODUCT_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: ALL_BRANDS_BY_PRODUCT_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const ALL_BMW_BRANDS = 'ALL_BMW_BRANDS';
export const ALL_BMW_BRANDS_SUCCESSFULL = 'ALL_BMW_BRANDS_SUCCESSFULL';
export const ALL_BMW_BRANDS_FAILED = 'ALL_BMW_BRANDS_FAILED';

export function getAllBmwBrands(data) {
    return dispatch => {
        dispatch({
            type: ALL_BMW_BRANDS
        })
        return new Promise((resolve, reject) => {
            getAllBmwBrandsAPI(data)
                .then((res) => {
                    dispatch({
                        type: ALL_BMW_BRANDS_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: ALL_BMW_BRANDS_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const ADD_BRAND = 'ADD_BRAND';
export const ADD_BRAND_SUCCESSFULL = 'ADD_BRAND_SUCCESSFULL';
export const ADD_BRAND_FAILED = 'ADD_BRAND_FAILED';

export function addBrandToBmw(data) {
    return dispatch => {
        dispatch({
            type: ADD_BRAND
        })
        return new Promise((resolve, reject) => {
            addBrandToBmwAPI(data)
                .then((res) => {
                    dispatch({
                        type: ADD_BRAND_SUCCESSFULL,
                        data: res
                    })
                    toast.success("Brand Added successfully.")
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: ADD_BRAND_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const UPDATE_BRAND = 'UPDATE_BRAND';
export const UPDATE_BRAND_SUCCESSFULL = 'UPDATE_BRAND_SUCCESSFULL';
export const UPDATE_BRAND_FAILED = 'UPDATE_BRAND_FAILED';

export function updateBrandFromBmw(data) {
    return dispatch => {
        dispatch({
            type: UPDATE_BRAND
        })
        return new Promise((resolve, reject) => {
            updateBrandFromBmwAPI(data)
                .then((res) => {
                    dispatch({
                        type: UPDATE_BRAND_SUCCESSFULL,
                        data: res
                    })
                    toast.success("Brand Updated successfully.")
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_BRAND_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const DELETE_BRAND = 'DELETE_BRAND';
export const DELETE_BRAND_SUCCESSFULL = 'DELETE_BRAND_SUCCESSFULL';
export const DELETE_BRAND_FAILED = 'DELETE_BRAND_FAILED';

export function deleteBrandFromBmw(data) {
    return dispatch => {
        dispatch({
            type: DELETE_BRAND
        })
        return new Promise((resolve, reject) => {
            deleteBrandFromBmwAPI(data)
                .then((res) => {
                    dispatch({
                        type: DELETE_BRAND_SUCCESSFULL,
                        data: res
                    })
                    toast.success("Brand Deleted successfully.")
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: DELETE_BRAND_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const SYNC_PRODUCT_ADMIN = 'SYNC_PRODUCT_ADMIN';
export const SYNC_PRODUCT_ADMIN_SUCCESSFULL = 'SYNC_PRODUCT_ADMIN_SUCCESSFULL';
export const SYNC_PRODUCT_ADMIN_FAILED = 'SYNC_PRODUCT_ADMIN_FAILED';

export function syncProductAdmin(data) {
    return dispatch => {
        dispatch({
            type: SYNC_PRODUCT_ADMIN
        })
        return new Promise((resolve, reject) => {
            syncProductAdminAPI(data)
                .then((res) => {
                    dispatch({
                        type: SYNC_PRODUCT_ADMIN_SUCCESSFULL,
                        data: res
                    })
                    toast.success(res.message || "Product synced successfully")
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: SYNC_PRODUCT_ADMIN_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}


// Threashold Actions

export const GET_THREASHOLD = 'GET_THREASHOLD';
export const GET_THREASHOLD_SUCCESSFULL = 'GET_THREASHOLD_SUCCESSFULL';
export const GET_THREASHOLD_FAILED = 'GET_THREASHOLD_FAILED';

export function getThreshold(data) {
    return dispatch => {
        dispatch({
            type: GET_THREASHOLD
        })
        return new Promise((resolve, reject) => {
            getThresholdAdminAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_THREASHOLD_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_THREASHOLD_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const UPDATE_THRESHOLD = 'UPDATE_THRESHOLD';
export const UPDATE_THRESHOLD_SUCCESSFULL = 'UPDATE_THRESHOLD_SUCCESSFULL';
export const UPDATE_THRESHOLD_FAILED = 'UPDATE_THRESHOLD_FAILED';

export function updateThreshold(data) {
    return dispatch => {
        dispatch({
            type: UPDATE_THRESHOLD
        })
        return new Promise((resolve, reject) => {
            updateThresholdAPI(data)
                .then((res) => {
                    dispatch({
                        type: UPDATE_THRESHOLD_SUCCESSFULL,
                        data: res
                    })
                    toast.success(res.message || "Threshold set successfully")
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_THRESHOLD_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}