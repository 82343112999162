import {
        GET_BANNER_TIMING,
        GET_BANNER_TIMING_SUCCESS,
        GET_BANNER_TIMING_FAILURE,
        UPDATE_BANNER_TIMING,
        UPDATE_BANNER_TIMING_SUCCESS,
        UPDATE_BANNER_TIMING_FAILURE,
        REQUEST_BANNERS_LIST,
        REQUEST_BANNERS_LIST_SUCCESSFULL,
        REQUEST_BANNERS_LIST_FAILED,
        GET_FHL_STATUS,
        GET_FHL_STATUS_SUCCESS,
        GET_FHL_STATUS_FAILURE,
        UPDATE_FHL_STATUS ,
        UPDATE_FHL_STATUS_SUCCESSFULL ,
        UPDATE_FHL_STATUS_FAILED,
} from "../actions/banner";


const initialState = {
        isLoading: false,
        allBanner: {},
        banners: [],
        fhlData : false
}

export default function banner(state = { ...initialState }, action = {}) {
        switch (action.type) {

                case GET_BANNER_TIMING:
                        return { ...state, isLoading: true }

                case GET_BANNER_TIMING_SUCCESS:
                        return { ...state, isLoading: false, allBanner: action?.data }

                case GET_BANNER_TIMING_FAILURE:
                        return { ...state, isLoading: false }

                case UPDATE_BANNER_TIMING:
                        return { ...state, isLoading: true }

                case UPDATE_BANNER_TIMING_SUCCESS:
                        return { ...state, isLoading: false }

                case UPDATE_BANNER_TIMING_FAILURE:
                        return { ...state, isLoading: false }

                case REQUEST_BANNERS_LIST:
                        return { ...state, isLoading: true }

                case REQUEST_BANNERS_LIST_SUCCESSFULL:
                        return { ...state, isLoading: false, banners: action?.data }

                case REQUEST_BANNERS_LIST_FAILED:
                        return { ...state, isLoading: false }
                case GET_FHL_STATUS:
                        return { ...state, isLoading: true }

                case GET_FHL_STATUS_SUCCESS:
                        return { ...state, isLoading: false, fhlData: action?.data }

                case GET_FHL_STATUS_FAILURE:
                        return { ...state, isLoading: false }
                case UPDATE_FHL_STATUS:
                        return { ...state, isLoading: true }

                case UPDATE_FHL_STATUS_SUCCESSFULL:
                        return { ...state, isLoading: false }

                case UPDATE_FHL_STATUS_FAILED:
                        return { ...state, isLoading: false }

                default:
                        return state
        }
}