import React from 'react'


import { Button, Grid, InputAdornment, List, ListItem, ListItemIcon, ListItemText, Divider, CircularProgress, Typography } from '@material-ui/core';
import CustomInput from '../../components/common/CustomInput';

import Checkbox from '@material-ui/core/Checkbox';
import SearchIcon from '@material-ui/icons/Search';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';



export default function UserSideBarDrawer(props) {
    const [checked, setChecked] = React.useState([]);

    const { allUsers = [], errors, setErrors = () => { }, handleSubmit = () => { }, handleClose = () => { }, search = '', handleSearch = () => { }, isLoading } = props


    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setErrors({
            ...errors,
            userId: ''
        });
        setChecked(newChecked);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CustomInput
                    name="search"
                    // label="Search"
                    value={search}
                    className="w-100 pt-1"
                    margin="normal"
                    placeholder="Search users"
                    onChange={handleSearch}
                    InputProps={{
                        startAdornment: <InputAdornment position="end">      <SearchIcon /></InputAdornment>,
                    }}
                    error={errors && errors.userId}
                />
            </Grid>
            <Grid item xs={12}>
                {!isLoading ? <div>
                    <List className="" style={{ height: '600px', overflowY: 'scroll' }}>
                        {allUsers && allUsers.length > 0 ? allUsers.map((value) => {
                            const labelId = `checkbox-list-label-${value._id}`;

                            return (
                                <>
                                    <ListItem key={value._id} role={undefined} dense button onClick={handleToggle(value._id)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                //icon={<CircleUnchecked />}
                                                checkedIcon={<CircleChecked />}
                                                edge="start"
                                                checked={checked.indexOf(value._id) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                color="primary"
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} primary={value && value.name || ''} />
                                    </ListItem>
                                    <Divider />
                                </>
                            );
                        }) :
                            <div style={{ height: '600px', overflowY: 'scroll' }} className="d-flex jc-ctr algn-itm-ctr">
                                <Typography >
                                    User not found!
                                </Typography>
                            </div>
                        }
                    </List>
                </div> :
                    <div style={{ height: '600px', overflowY: 'scroll' }} className="d-flex jc-ctr algn-itm-ctr">
                        <CircularProgress color="primary" />
                    </div>}

            </Grid>
            <Grid item xs={12}>
                <div className="d-flex-end">
                    <Button
                        onClick={() => { handleSubmit(checked) }}
                        variant="contained"
                        color="primary"
                        disabled={checked && checked.length == 0}
                    >
                        Save
                </Button>
                    <Button
                        style={{ marginLeft: "10px" }}
                        onClick={() => handleClose()}
                        variant="contained"
                        color="primary"
                    >
                        Cancel
             </Button>
                </div>
            </Grid>
        </Grid>
    )
}
