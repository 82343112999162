import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import * as ordersActions from '../../redux/actions/orders';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    scrollBehavior: 'smooth',
  },
}));

const FhlOrder = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { firstTimeOrder, restOrder } = useSelector((state) => state?.orders?.fhlOrder);
  
  const [updatedFirstTimeOrder, setUpdatedFirstTimeOrder] = useState(firstTimeOrder);
  const [updatedRestOrder, setUpdatedRestOrder] = useState(restOrder);

  useEffect(() => {
    dispatch(ordersActions.getFhlOrder());
  }, []);

  useEffect(() => {
    setUpdatedFirstTimeOrder(firstTimeOrder);
    setUpdatedRestOrder(restOrder);
  }, [firstTimeOrder, restOrder]);

  const handleOrderUpdate = (field, value) => {
    if (field === 'firstTimeOrder') {
      setUpdatedFirstTimeOrder(value);
    } else if (field === 'restOrder') {
      setUpdatedRestOrder(value);
    }
  };

  const updateOrders = () => {
    dispatch(ordersActions.updateFhlOrder({ firstTimeOrder: updatedFirstTimeOrder, restOrder: updatedRestOrder }));
    setTimeout(() => {
      window.location.reload();
    }, 2000); 
  };

  return (
    <Grid container className="shop_timing">
      <Grid item xs={12} className="shop_timing_container">
        <Grid>
          <form className={classes.container} noValidate>
            <TextField
              id="first-time-order"
              label="First Order Limit"
              type="number"
              value={updatedFirstTimeOrder}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleOrderUpdate('firstTimeOrder', e.target.value)}
            />
          </form>
        </Grid>
        <Grid>
          <form className={classes.container} noValidate>
            <TextField
              id="rest-order"
              label="Rest Order Limit"
              type="number"
              value={updatedRestOrder}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleOrderUpdate('restOrder', e.target.value)}
            />
          </form>
        </Grid>
      </Grid>
      <Grid className="w-100">
        <Grid className="time_button">
          <Button variant="contained" color="primary" className='abc' onClick={updateOrders}>
            Update Limit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FhlOrder;
