import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as actions from "../../redux/actions/strains"
import { useDispatch } from 'react-redux';


export default function CommonEditor({ id, value, onChange }) {
  // const [value, setValue] = useState('');
  const quillObj = useRef(null)
  const [imageFiles, setImageFiles] = useState([])
  const [mount, setMount] = useState(false)
  const dispatch = useDispatch()

  // const imageHandler = useCallback(() => {
  //   const formData = new FormData()
  //   const input = document.createElement('input')
  //   input.setAttribute('type', 'file')
  //   input.setAttribute('accept', 'image/*')
  //   input.click()

  //   input.onchange = async () => {
  //     const file = input.files[0]
  //     try {
  //       console.log("file", file)
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file)
  //       formData.append('image', file)
  //       reader.addEventListener('load', () => {
  //         // Get the data URL string
  //         console.log(quillObj?.current.getEditor())
  //         const range = quillObj.current.getEditorSelection();
  //         var res = 'https://plus.unsplash.com/premium_photo-1671650124341-1f62adedf78f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60'
  //         quillObj.current.getEditor().insertEmbed(range.index, 'image', reader.result);
  //         // // quillObj.current.getEditor().formatText(range.index, range.index + 1, 'height', '20%');
  //         // console.log(reader.result);
  //         // setImageFiles([...imageFiles, file])
  //       });

  //     } catch (err) {
  //       console.log('upload err:', err)
  //     }
  //   }
  // }, [])

  useEffect(() => {
    setMount(true)
    return () => {
      setMount(false)
    }
  }, [])

  const onChangehandler = (content, id) => {
    onChange(content, id)
  }

  // useEffect(() => {
  //   if (
  //     quillObj.current?.lastDeltaChangeSet?.ops[1]?.delete === 1 &&
  //     imageFiles.length > 0
  //   ) {
  //     for (let index = 0; index < imageFiles.length; index++) {
  //       if (!quillObj.current?.value.includes(imageFiles[index].path)) {
  //         const tempImageFiles = [...imageFiles]
  //         const filteredIamgeFiles = tempImageFiles.filter(
  //           (image) => image.id !== imageFiles[index].id
  //         )
  //         // deleteImage(imageFiles[index].id)
  //         // console.log("delete imagw", imageFiles[index])
  //         // setImageFiles(filteredIamgeFiles)
  //       }
  //     }
  //   }
  // }, [quillObj.current?.lastDeltaChangeSet?.ops[1]?.delete])
  const API_URL = "https://77em4-8080.sse.codesandbox.io";
  const UPLOAD_ENDPOINT = "upload_files";
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("filePath", file);
            dispatch(actions.uploadStrainImage(body))
              .then((res) => {
                resolve({
                  default: res.fileUrl
                });
              })
              .catch((err) => {
                resolve({
                  default: `${process.env.REACT_APP_PUBLIC_BASE_URL}test.jpg`
                });
                reject(err);
              });
          });
        });
      }
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <>
      {/* <ReactQuill
        ref={(el) => {
          quillObj.current = el;
        }}
        value={value}
        modules={{
          toolbar: {
            container: [
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'align': [] }],
              ['link', 'image'],
              ['clean'],
              [{ 'color': [] }]
            ],
            handlers: {
              image: imageHandler
            },
            imageResize: {
              displayStyles: {
                backgroundColor: 'black',
                border: 'none',
                color: 'white',
              },
              modules: ['Resize', 'DisplaySize', 'Toolbar']
            }
          },

          // table: true
        }}
        // style={{ height: '' }}
        // placeholder="Add a description of your event"
        onChange={(content, _, source) => {
          if (!mount) return;
          onChangehandler(content, id)
        }}
        id={id}
      /> */}
      <CKEditor
        editor={ClassicEditor}
        data={value}
        config={{
          extraPlugins: [uploadPlugin]
        }}
        // onReady={editor => {
        //   // You can store the "editor" and use when it is needed.
        //   console.log('Editor is ready to use!', editor);
        // }}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange(data, id)
        }}
      // onBlur={(event, editor) => {
      //   console.log('Blur.', editor);
      // }}
      // onFocus={(event, editor) => {
      //   console.log('Focus.', editor);
      // }}
      />
    </>
  );
}
