import { apiGet, apiPost,apiDelete, apiPut } from '../../utils';

export function getEmployeesAPI(data) {
    return apiGet('api/v1/hyperdrive/get/employees',data);
}

export function addEmployeeAPI(data, id = '') {
    if (id) {
        return apiPut(`api/v1/hyperdrive/update/${id}`, data);
    }
    return apiPost('api/v1/hyperdrive/create/employee', data);
}

export function deleteEmployeeAPI(id) {
    return apiDelete(`api/v1/hyperdrive/delete/employee/${id}`)
}
