import { getEmployeesAPI, addEmployeeAPI, deleteEmployeeAPI } from "../api/employee";
import { toast } from 'react-toastify';

export const GET_ALL_EMPLOYEE = 'GET_ALL_EMPLOYEE';
export const GET_ALL_EMPLOYEE_SUCCESS = 'GET_ALL_EMPLOYEE_SUCCESS'
export const GET_ALL_EMPLOYEE_FAILED = 'GET_ALL_EMPLOYEE_FAILED'

export function getAllEmployee(data) {
    return dispatch => {
        dispatch({ type: GET_ALL_EMPLOYEE })
        return new Promise((resolve, reject) => {
            getEmployeesAPI(data).then(res => {
                dispatch({ type: GET_ALL_EMPLOYEE_SUCCESS, payload: res })
                resolve(res)
            }).catch((err) => {
                dispatch({ type: GET_ALL_EMPLOYEE_FAILED })
                reject(err)
            })
        })
    }
}

export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS'
export const ADD_EMPLOYEE_FAILED = 'ADD_EMPLOYEE_FAILED'

export function addEmployee(data, id) {
    return dispatch => {
        dispatch({ type: ADD_EMPLOYEE })
        return new Promise((resolve, reject) => {
            addEmployeeAPI(data, id).then(res => {
                dispatch({ type: ADD_EMPLOYEE_SUCCESS, payload: res })
                toast.success(res.message)
                resolve(res)
            }).catch((err) => {
                dispatch({ type: ADD_EMPLOYEE_FAILED })
                reject(err)
            })
        })
    }
}

export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS'
export const DELETE_EMPLOYEE_FAILED = 'DELETE_EMPLOYEE_FAILED'

export function deleteEmployee(id) {
    return dispatch => {
        dispatch({ type: DELETE_EMPLOYEE })
        return new Promise((resolve, reject) => {
            deleteEmployeeAPI(id).then(res => {
                dispatch({ type: DELETE_EMPLOYEE_SUCCESS, payload: res })
                toast.success(res.message)
                resolve(res)
            }).catch((err) => {
                dispatch({ type: DELETE_EMPLOYEE_FAILED })
                reject(err)
            })
        })
    }
}