import React, { useState, useEffect, useRef, useMemo } from 'react';
import 'react-phone-input-2/lib/style.css';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom"
import { Button, Typography, CircularProgress, Grid, Box, Select, MenuItem, FormHelperText, FormControl } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash"
import CustomInput from '../../components/common/CustomInput';
import * as actions from "../../redux/actions/banner"
import * as commonActions from "../../redux/actions/common"
import { validateEmployeeData } from '../../utilities/validations/employee';
import * as StrainActions from "../../redux/actions/strains"
import CommonEditor from '../../components/common/CommonEditor';


const initialEmployee = {
    name: "",
    email: "",
    phone: "",
    // address: "",
    // source:"",
}

const mediaTypes = [
    {
        label: "Video",
        value: "video"
    },
    {
        label: "Image",
        value: "image"
    },
]

function AddCategoryBannerModal(props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch()

    const { modalData } = useSelector(state => state.common)
    const { isLoading = false } = useSelector(state => state.employee)
    const [phoneError, setPhoneError] = useState("");
    const { title = "Add", data: editData = {} } = modalData

    const [banners, setBanners] = useState({ ...initialEmployee })
    const [errors, setErrors] = useState({ ...initialEmployee })
    const isEditdata = !_.isEmpty(editData)
    const mobileImageRef = useRef()
    const desktopImageRef = useRef()

    useEffect(() => {
        if (isEditdata) {
            setBanners({ ...editData });
        }
    }, [isEditdata, editData]);

    const mediaType = useMemo(() => {
        return banners?.media_type
    }, [banners])

    console.log("banners", banners)

    const onChangehandler = (e) => {
        const { name, value } = e.target;
        setBanners({ ...banners, [name]: value });
        if (name === "phone") {
            setPhoneError("");
        }
    };


    const addBanner = () => {
        const { errors: validationErrors, isValid: isDataValid } = validateEmployeeData(banners);
        // setErrors(validationErrors);
        dispatch(actions.updateBanner(banners, (banners._id || ''))).then(res => {
            dispatch(actions.getBanners());
            dispatch(commonActions.closeCustomModalDialog());
        });

    };

    const imageUploadHandler = (key, file) => {
        let formData = new FormData()
        formData.append("filePath", file);
        dispatch(StrainActions.uploadStrainImage(formData))
            .then((res) => {
                setBanners({ ...banners, [key]: res.fileUrl })
            })
            .catch((err) => {
                setBanners({ ...banners, [key]: `${process.env.REACT_APP_PUBLIC_BASE_URL}test.jpg` })
            });
    }


    const onEditorChangehandler = (value, name) => {
        setBanners({ ...banners, [name]: value });
    };


    const validatePhoneNumber = (phone) => {
        const numericPhone = phone.replace(/\D/g, ""); // Extract numeric digits only
        return numericPhone.length > 8 && numericPhone.length <= 11;
    };

    return (
        <Grid container spacing={4} component="main" className={`${classes.root} add-zipcode-modal-container`}>
            <Grid item xs={12} className="pt-2">
                <Typography variant="h4" className="d-flex jc-ctr">{title} Banner</Typography>
            </Grid>

            <Grid item xs={12} className="add-category-input">
                <Grid item xs={12}>
                    <Box my={4}>
                        <Typography style={{ marginBottom: '10px', fontWeight: '500' }}>Desktop Image</Typography>
                        <input ref={desktopImageRef} style={{ display: 'none' }} type='file' onChange={(e) => imageUploadHandler('desktop_image', e.target.files[0])} />

                        <Box style={{ cursor: 'pointer' }} onClick={() => desktopImageRef.current.click()}>
                            {
                                banners?.desktop_image ? <img style={{ borderRadius: '10px' }} src={banners.desktop_image} height={300} width={500} /> : <img style={{ borderRadius: '10px', objectFit: 'cover' }} src={`https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png`} height={300} width={500} />
                            }
                        </Box>

                    </Box>

                </Grid>
                {/* <Grid item xs={12} >
                    <Box my={4}>
                        <Typography style={{ marginBottom: '10px', fontWeight: '500' }}>Mobile Image</Typography>
                        <input ref={mobileImageRef} type='file' style={{ display: 'none' }} onChange={(e) => imageUploadHandler('mobile_image', e.target.files[0])} />
                        <Box style={{ cursor: 'pointer' }} onClick={() => mobileImageRef.current.click()}>
                            {
                                banners?.mobile_image ? <img src={banners.mobile_image} style={{ borderRadius: '10px' }} height={300} width={500} /> : <img style={{ borderRadius: '10px', objectFit: 'cover' }} src={`https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png`} height={300} width={500} />
                            }

                        </Box>
                    </Box>
                </Grid> */}
                <Grid item xs={12}>
                    <Typography style={{margin:'10px 0px'}}>Media Type</Typography>
                    <Select variant='outlined' fullWidth value={mediaType} onChange={(e) => setBanners({ ...banners, media_type: e.target.value })}>
                        <MenuItem value='video'>Video</MenuItem>
                        <MenuItem value='image'>Image</MenuItem>
                    </Select>
                </Grid>

                <Grid item xs={12}>
                <Typography style={{marginTop:'15px'}}>Redirect URL</Typography>
                    <CustomInput
                        name="url"
                        value={banners?.url || ""}
                        onChange={(e) => { setBanners({ ...banners, url: e.target.value }) }}
                        label="URL"
                        className="w-100"
                        margin="normal"
                        error={errors.url}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Typography style={{ fontSize: '14px',margin:'10px 0px' }}>Top Area Text</Typography>
                        <CommonEditor onChange={onEditorChangehandler} id={"topareatext"} value={banners.topareatext} />
                        {/* {
                            errors.description && <Typography>{errors.description}</Typography>
                        } */}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Typography style={{ fontSize: '14px',margin:'10px 0px' }}>Bottom Area Text</Typography>
                        <CommonEditor onChange={onEditorChangehandler} id={"bottomareatext"} value={banners.bottomareatext} />
                        {/* {
                            errors.title && <FormHelperText>{errors.title}</FormHelperText>
                        } */}
                    </FormControl>
                </Grid>

                <Grid item xs={12} className="d-flex jc-ctr add-button">
                    <Button onClick={addBanner} variant="contained" color="primary" fullWidth>{isLoading ? <CircularProgress size={22} style={{ color: "white" }} /> : 'Submit'}</Button>
                </Grid>
            </Grid>

        </Grid >
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));


export default AddCategoryBannerModal