import React, { useState, useEffect, useRef, useMemo } from 'react';
import 'react-phone-input-2/lib/style.css';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom"
import { Button, Typography, CircularProgress, Grid, Box, Select, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash"
import CustomInput from '../../components/common/CustomInput';
import * as actions from "../../redux/actions/banner"
import * as commonActions from "../../redux/actions/common"
import * as StrainActions from "../../redux/actions/strains"
import { toast } from 'react-toastify';
import { validateBannerData } from '../../utilities/validations/banners';


const initialEmployee = {
    banner_position: "",
    desktop_image: "",
    media_type: "",
    mobile_image: '',
    url: ""
}

const mediaTypes = [
    {
        label: "Video",
        value: "video"
    },
    {
        label: "Image",
        value: "image"
    },
]

function AddBannerModal(props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch()

    const { modalData } = useSelector(state => state.common)
    const { isLoading = false } = useSelector(state => state.banner)
    const [phoneError, setPhoneError] = useState("");
    const { title = "Add", data: editData = {} } = modalData

    const [banners, setBanners] = useState({ ...initialEmployee })
    const [errors, setErrors] = useState({ ...initialEmployee })
    const isEditdata = !_.isEmpty(editData)
    const mobileImageRef = useRef()
    const desktopImageRef = useRef()
    const [isDesktopUploading, setIsDesktopUploading] = useState(false)
    const [isMobileUploading, setIsMobileUploading] = useState(false)

    useEffect(() => {
        if (isEditdata) {
            setBanners({ ...editData });
        }
    }, [isEditdata, editData]);

    const mediaType = useMemo(() => {
        return banners?.media_type
    }, [banners])

    const onChangehandler = (e) => {
        const { name, value } = e.target;
        setBanners({ ...banners, [name]: value });
        if (name === "phone") {
            setPhoneError("");
        }
    };

    const addBanner = () => {
        const { errors: validationErrors, isValid: isDataValid } = validateBannerData(banners);
        if (!isDataValid) {
            setErrors(validationErrors);
            return;
        }
        dispatch(actions.updateBanner(banners, (banners._id || ''))).then(res => {
            dispatch(actions.getBanners());
            dispatch(commonActions.closeCustomModalDialog());
        });

    };

    const imageUploadHandler = (key, file) => {
        if (mediaType === 'video') {
            let fname = file.name
            let re = /(\.webm|\.mp4)$/i;
            if (!re.exec(fname)) {
                toast.error("File extension not supported!");
                return;
            }
        }
        if (mediaType === 'image') {
            let fname = file.name
            let re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
            if (!re.exec(fname)) {
                toast.error("File extension not supported!");
                return;
            }
        }
        if (key === 'desktop_image') {
            setIsDesktopUploading(true)
        }
        if (key === 'mobile_image') {
            setIsMobileUploading(true)
        }
        let formData = new FormData()
        formData.append("filePath", file);
        dispatch(StrainActions.uploadStrainImage(formData))
            .then((res) => {
                let errorsObj = {...errors}
                if(errorsObj[key]){
                    delete errors[key]
                    setErrors({...errors})
                }
                setBanners({ ...banners, [key]: res.fileUrl })
                if (key === 'desktop_image') {
                    setIsDesktopUploading(false)
                }
                if (key === 'mobile_image') {
                    setIsMobileUploading(false)
                }

            })
            .catch((err) => {
                setBanners({ ...banners, [key]: `${process.env.REACT_APP_PUBLIC_BASE_URL}test.jpg` })
                if (key === 'desktop_image') {
                    setIsDesktopUploading(false)
                }
                if (key === 'mobile_image') {
                    setIsMobileUploading(false)
                }
            });
    }

    const validatePhoneNumber = (phone) => {
        const numericPhone = phone.replace(/\D/g, ""); // Extract numeric digits only
        return numericPhone.length > 8 && numericPhone.length <= 11;
    };

    return (
        <>

            {
                isLoading ? <>
                    <CircularProgress />
                </> : <>
                    <Grid container spacing={4} component="main" className={`${classes.root} add-zipcode-modal-container`}>
                        <Grid item xs={12} className="pt-2">
                            <Typography variant="h4" className="d-flex jc-ctr">{title} Banner</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ margin: '10px 0px' }}>Media Type</Typography>
                            <Select key={mediaType} variant='outlined' name='mediaType' fullWidth value={mediaType} onChange={(e) => setBanners({ ...banners, media_type: e.target.value, desktop_image: "", mobile_image: "" })}>
                                <MenuItem value='video'>Video</MenuItem>
                                <MenuItem value='image'>Image</MenuItem>
                            </Select>
                            {
                                errors?.media_type && <p className="error">{errors?.media_type}</p>
                            }
                        </Grid>
                        <Grid item xs={12}>
                                <Typography style={{ margin: '10px 0px' }}>Banner Position</Typography>
                                <Select key={banners?.banner_position} variant='outlined' name='banner_position' fullWidth value={banners?.banner_position} onChange={(e) => setBanners({ ...banners, banner_position: e.target.value })}>
                                    <MenuItem value='left'>Left</MenuItem>
                                    <MenuItem value='right'>Right</MenuItem>
                                </Select>
                                {
                                    errors?.banner_position && <p className="error">{errors?.banner_position}</p>
                                }
                            </Grid>
                        <Grid item xs={12} className="add-category-input">
                            <Grid item xs={12}>
                                <Box >
                                    <Typography style={{ marginBottom: '10px', fontWeight: '500', textTransform: 'capitalize' }}>Desktop {mediaType}</Typography>
                                    <input accept={mediaType === 'video' ? ".mp4,.webm" : '.png,.jpg,.jpeg,.gif'} ref={desktopImageRef} style={{ display: 'none' }} type='file' onChange={(e) => imageUploadHandler('desktop_image', e.target.files[0])} />
                                    {
                                        isDesktopUploading ? <>
                                            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                                <CircularProgress size={50} />
                                            </Box>
                                        </> : <Box style={{ cursor: 'pointer' }} onClick={() => {
                                            if (!mediaType) return toast.error('Plase select media type');
                                            desktopImageRef.current.click()
                                        }}>
                                            {
                                                (!isDesktopUploading && banners?.desktop_image) ? mediaType === 'video' ? <video height={300} width={500} loop autoPlay playsInline muted>
                                                    <source key={banners.desktop_image} src={banners.desktop_image} style={{ borderRadius: '10px' }} type="video/webm" />
                                                    <source key={banners.desktop_image} src={banners.desktop_image} style={{ borderRadius: '10px' }} type="video/mp4" />
                                                </video> : <img style={{ borderRadius: '10px' }} src={banners.desktop_image} height={300} width={500} /> : <img style={{ borderRadius: '10px', objectFit: 'cover' }} src={`https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png`} height={300} width={500} />
                                            }
                                        </Box>
                                    }
                                    {
                                        errors?.desktop_image && <p className="error">{errors?.desktop_image}</p>
                                    }
                                    {
                                        banners?.banner_position &&     <Typography color='red'><strong>Note:</strong> {banners?.banner_position==='left' ? "Banner image should be 873x365" : "Banner image should be 275x365" }  </Typography>
                                    }
                                
                                </Box>

                            </Grid>
                            <Grid item xs={12} >
                                <Box my={4}>
                                    <Typography style={{ marginBottom: '10px', fontWeight: '500' }}>Mobile {mediaType}</Typography>
                                    <input accept={mediaType === 'video' ? ".mp4,.webm" : '.png,.jpg,.jpeg,.gif'} ref={mobileImageRef} type='file' style={{ display: 'none' }} onChange={(e) => imageUploadHandler('mobile_image', e.target.files[0])} />
                                    {
                                        isMobileUploading ? <>
                                            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                                <CircularProgress size={50} />
                                            </Box>

                                        </> : <Box style={{ cursor: 'pointer' }} onClick={() => {
                                            if (!mediaType) return toast.error('Plase select media type');
                                            mobileImageRef.current.click()
                                        }}>
                                            {
                                                banners?.mobile_image ? mediaType === 'video' ? <video height={300} width={500} loop autoPlay playsInline muted>
                                                    <source key={banners.mobile_image} src={banners.mobile_image} style={{ borderRadius: '10px' }} height={300} width={500} type="video/mp4" />
                                                    <source key={banners.mobile_image} src={banners.mobile_image} style={{ borderRadius: '10px' }} height={300} width={500} type="video/webm" />
                                                </video> : <img src={banners.mobile_image} style={{ borderRadius: '10px' }} height={300} width={500} /> : <img style={{ borderRadius: '10px', objectFit: 'cover' }} src={`https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png`} height={300} width={500} />
                                            }

                                        </Box>
                                    }
                                    {
                                        errors?.mobile_image && <p className="error">{errors?.mobile_image}</p>
                                    }
                                      {
                                        banners?.banner_position &&     <Typography color='red'><strong>Note:</strong> {banners?.banner_position==='left' ? "Banner image should be 873x365" : "Banner image should be 275x365" }  </Typography>
                                    }
                                </Box>
                            </Grid>


                            <Grid item xs={12} >
                                <Typography style={{ marginTop: '15px' }}>URL</Typography>
                                <CustomInput
                                    name="url"
                                    value={banners?.url || ""}
                                    onChange={(e) => { setBanners({ ...banners, url: e.target.value }) }}
                                    label="URL"
                                    className="w-100"
                                    margin="normal"
                                    error={errors.url}
                                />
                            </Grid>
                         

                            <Grid item xs={12} className="d-flex jc-ctr add-button">
                                <Button onClick={addBanner} variant="contained" color="primary" fullWidth>{isLoading ? <CircularProgress size={22} style={{ color: "white" }} /> : 'Submit'}</Button>
                            </Grid>
                        </Grid>

                    </Grid >
                </>
            }
        </>

    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));


export default AddBannerModal