import React, { useEffect } from 'react';
import { Button, Grid, Typography, Tooltip, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AllPromotions from './AllPromotions';
import { useDispatch, useSelector } from 'react-redux';
import * as commonActions from "../../redux/actions/common"
import * as productsActions from "../../redux/actions/products"
import * as promotionsActions from "../../redux/actions/promotions"
import SpecialProductsList from './SpecialProductsList';
import InfoIcon from '@material-ui/icons/Info';
import { useState } from 'react';
import CommonLoading from '../../components/common/CommonLoading';

function PromotionsPage() {
    const classes = useStyles();
    const dispatch = useDispatch()

    const { specialProducts = {}, allPromotions, isLoading } = useSelector(state => state.promotions)

    const [specialProductsList, setSpecialProductsLits] = useState([])
    const [syncLoader, setSyncLoader] = useState(false)

    const { values = [] } = specialProducts || {}
    const { values: promotionsValues = [] } = allPromotions

    useEffect(() => {
        dispatch(productsActions.getAllProducts({ productTags: "featured", limit: 200 }))
        hitSpProductsAPI()
        hitGetAllPromotionAPI()
    }, [])

    useEffect(() => {
        setSpecialProductsLits([...(values || [])])
    }, [values])

    //Promotion

    const hitGetAllPromotionAPI = () => {
        dispatch(promotionsActions.getAllPromotion())
    }

    const handleConfirmationPromotion = (id) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Are you sure you want to Delete?",
            confirm: () => { handleDeletePromotion(id) },
            cancel: () => { }
        }, "ConfirmationModal"))
    }

    const handleDeletePromotion = () => {

    }

    const handleEditPromotion = (item) => {
        dispatch(commonActions.openCustomModalDialog({ data: item }, "EditPromotion"))
    }

    const syncPromotions = () => {
        setSyncLoader(true)
        promotionsActions.syncPromotion().then(() => {
            setSyncLoader(false)
            hitGetAllPromotionAPI()
        }).catch(() => setSyncLoader(false))
    }

    //Special Products
    const hitSpProductsAPI = () => {
        dispatch(promotionsActions.getSpecialProducts())
    }

    const handleConfirmationProduct = (id) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Are you sure you want to Delete?",
            confirm: () => { handleDeleteProduct(id) },
            cancel: () => { }
        }, "ConfirmationModal"))
    }

    const handleDeleteProduct = (id) => {
        let updatedList = [...specialProductsList]
        let items = updatedList.filter(v => v.id !== id)

        setSpecialProductsLits([...items])

        let newOrderIds = items.map(v => v.id)
        dispatch(promotionsActions.updateSpecialProducts({ products: newOrderIds }))
    }

    const addSpecialProduct = (id) => {
        dispatch(commonActions.openCustomModalDialog({}, "AddSpecialProduct"))
    }

    const onUpdateListOrder = (items) => {
        setSpecialProductsLits([...items])
        let newOrderIds = [...items].map(v => v.id)
        dispatch(promotionsActions.updateSpecialProducts({ products: newOrderIds }))
    }


    return (
        <Grid container component="main" className={`${classes.root} promotions-container`}>
            <Grid item xs={12} className="top-btn-sec">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={syncPromotions}
                >
                    {syncLoader ? <CircularProgress size={20} style={{ color: "white" }} /> : 'Sync Promotions'}
                </Button>
            </Grid>
            <Grid item xs={12}>
                <AllPromotions
                    handleDeletePromotion={handleConfirmationPromotion}
                    promotionsValues={promotionsValues || []}
                    handleEditPromotion={handleEditPromotion}

                />
            </Grid>


            <Grid item xs={12} className="promotions-specials-sec">
                <div className="specials-top-sec">
                    <Typography className="info"><InfoIcon /> Products Visible in carousel at Home Page.</Typography>
                    <Button
                        onClick={() => { addSpecialProduct() }}
                        variant="contained"
                        color="primary"
                    >
                        Add Product
                    </Button>
                </div>
                <SpecialProductsList
                    handleDeleteList={handleConfirmationProduct}
                    list={specialProductsList || []}
                    onUpdateListOrder={onUpdateListOrder}
                />
            </Grid>

            <CommonLoading isLoading={isLoading} />
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },

}));


export default PromotionsPage