import React, { useEffect, useState } from 'react'
import { isSuperAdminAccess } from "../../utils"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/employee";
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import * as commonActions from "../../redux/actions/common"

import {
    Grid,
    CircularProgress,
    TableContainer,
    Table,
    Button,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    Box
} from "@material-ui/core"
import CommonLoading from '../../components/common/CommonLoading'
import CustomTable from '../../components/common/CustomTable';

function Employees() {

    const history = useHistory()
    const dispatch = useDispatch()
    
    const {
        allEmployees = [],
        
        isLoading = false,
        
    } = useSelector(state => state.employee) || {}

    
const [pagination, setPagination] = useState({
    limit: 200,
    skip: 0,
  });
  

  useEffect(() => {
    dispatch(actions.getAllEmployee(pagination));
  }, [pagination]);
  

  const handleNextPage = () => {
    setPagination(prevPagination => ({
      ...prevPagination,
      skip: prevPagination.skip + prevPagination.limit,
    }));
  };
    
    console.log("AllEmployees", allEmployees)

    const handleConfirmation = (id) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Are you sure you want to Delete?",
            confirm: () => { onDeleteAdmin(id) },
            cancel: () => { }
        }, "ConfirmationModal"))
    }

    const onDeleteAdmin = (id) => {
        dispatch(actions.deleteEmployee(id)).then(res => {
            dispatch(actions.getAllEmployee())
        })
    }

    const onEditAdmin = (item) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Edit",
            data: item
        }, "AddEmployeModal"))
    }

    return (
        <Grid container component="main" className="admin-container">
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <div className="custom-button-div">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => { dispatch(commonActions.openCustomModalDialog({}, "AddEmployeModal")) }}
                            >Add Employee</Button>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                    <Box mt={3}>
                    <CustomTable
                        tableHeading={["S.no", "name", "Email","Phone", "Created Date", "Actions"]}
                        rowsData={allEmployees.map((item, index) => (
                            <UserTableRow
                                key={item._id}
                                item={item}
                                index={index}
                                onEditAdmin={onEditAdmin}
                                onDeleteAdmin={handleConfirmation}
                                handleNextPage={handleNextPage}
                            />
                        ))}
                    />
                     </Box>
            </Grid>
                </Grid>
            </Grid>
            <CommonLoading isLoading={isLoading} />
        </Grid>
    )
}

export default Employees


const UserTableRow = ({ item, index, onDeleteAdmin, onEditAdmin }) => {
    return (
        <>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{item?.name}</TableCell>
            <TableCell>{item?.email}</TableCell>
            <TableCell>+{item?.phone}</TableCell>

            <TableCell>{new Date(item?.createdDate).toLocaleDateString()}</TableCell>
            <TableCell>
                <div>
                    <EditIcon className="cursor-pointer" onClick={() => onEditAdmin(item)} />
                    <DeleteOutlineIcon className="cursor-pointer" onClick={() => onDeleteAdmin(item._id)} />
                </div>
            </TableCell>
        </>
    );
};
