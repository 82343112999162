import React, { useEffect, useState } from 'react'
import { isSuperAdminAccess } from "../../utils"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/banner";
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import * as commonActions from "../../redux/actions/common"

import {
    Grid,
    CircularProgress,
    TableContainer,
    Table,
    Button,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    Box
} from "@material-ui/core"
import CommonLoading from '../../components/common/CommonLoading'
import CustomTable from '../../components/common/CustomTable';

function ManageBanners() {

    const history = useHistory()
    const dispatch = useDispatch()
    const { isLoading, banners } = useSelector((state) => state?.banner);
    const [pagination, setPagination] = useState({
        limit: 20,
        skip: 0,
    });

    useEffect(() => {
        dispatch(actions.getBanners(pagination));
    }, [pagination]);


    const handleNextPage = () => {
        setPagination(prevPagination => ({
            ...prevPagination,
            skip: prevPagination.skip + prevPagination.limit,
        }));
    };

    const handleConfirmation = (id) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Are you sure you want to Delete?",
            confirm: () => { onDeleteAdmin(id) },
            cancel: () => { }
        }, "ConfirmationModal"))
    }

    const onDeleteAdmin = (id) => {
        dispatch(actions.deleteBanner(id)).then(res => {
            dispatch(actions.getBanners(pagination));
        })
    }

    const onEditAdmin = (item) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Edit",
            data: item
        }, "AddBannerModal"))
    }

    return (
        <Grid container component="main" className="admin-container">
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <div className="custom-button-div">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => { dispatch(commonActions.openCustomModalDialog({}, "AddBannerModal")) }}
                            >Add Banner</Button>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mt={3}>
                            <CustomTable
                                tableHeading={["S.no", "Desktop", "Mobile", "Banners Position", "url", "Media Type", "actions"]}
                                rowsData={banners?.banners?.map((item, index) => (
                                    <UserTableRow
                                        key={item._id}
                                        item={item}
                                        index={index}
                                        onEditAdmin={onEditAdmin}
                                        onDeleteAdmin={handleConfirmation}
                                        handleNextPage={handleNextPage}
                                    />
                                ))}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <CommonLoading isLoading={isLoading} />
        </Grid>
    )
}

export default ManageBanners


const UserTableRow = React.memo(({ item, index, onDeleteAdmin, onEditAdmin }) => {
    return (
        <>
            <TableCell>{index + 1}</TableCell>
            <TableCell>
                {
                    item?.media_type === 'video' ? <video width={200} height={200} loop autoPlay playsInline muted>
                        <source key={item.desktop_image} src={item.desktop_image} style={{ borderRadius: '10px' }} type="video/mp4" />
                    </video> : <img src={item.desktop_image} style={{ borderRadius: '10px' }} width={200} height={200} />
                }
                {/* <img src={item?.desktop_image} alt={item?.desktop_image} width={200} height={200} style={{ objectFit: 'cover' }} /> */}
            </TableCell>
            <TableCell>
                {
                    item?.media_type === 'video' ? <video width={200} height={200} loop autoPlay playsInline muted>
                        <source key={item.mobile_image} src={item.mobile_image} style={{ borderRadius: '10px' }} type="video/mp4" />
                    </video> : <img src={item.mobile_image} style={{ borderRadius: '10px' }} width={200} height={200} />
                }
                {/* <img src={item?.mobile_image} alt={item?.mobile_image} width={200} height={200} style={{ objectFit: 'cover' }} /> */}
            </TableCell>
            <TableCell style={{ textTransform: 'capitalize' }}>{item?.banner_position}</TableCell>
            <TableCell>{item?.url}</TableCell>
            <TableCell>{item?.media_type}</TableCell>
            <TableCell>
                <div>
                    <EditIcon className="cursor-pointer" onClick={() => onEditAdmin(item)} />
                    <DeleteOutlineIcon className="cursor-pointer" onClick={() => onDeleteAdmin(item._id)} />
                </div>
            </TableCell>
        </>
    );
}
) 