import React, {useState, useEffect, useCallback} from 'react'
import { Grid, Button } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import * as authActions from "../../redux/actions/auth"
import { useDispatch, useSelector } from 'react-redux';



const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    scrollBehavior : 'smooth',
  },
}));


const ShopTiming = () => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const [updateData, setUpdateData] = useState({
    shopCloseTime : '',
    shopOpenTime : '',
    specailAddressOpenTime : ''
  })

  const { shopeTimeData={} } = useSelector(state => state.auth)
  const { shopCloseTime= "00:00", shopOpenTime = "00:00",specailAddressOpenTime = "00:00" } = shopeTimeData

  useEffect(()=>{dispatch(authActions.shopOpenTime())},[])

  const updateShoptime = () => {
        dispatch(authActions.shopUpdateTime({
          shopCloseTime : updateData.shopCloseTime? reverseTimeFormat(updateData.shopCloseTime)  : shopCloseTime ,
          shopOpenTime :  updateData.shopOpenTime? reverseTimeFormat(updateData.shopOpenTime) : shopOpenTime ,
          specailAddressOpenTime : updateData.specailAddressOpenTime? reverseTimeFormat(updateData.specailAddressOpenTime) : specailAddressOpenTime,
        })).then(()=>{
          dispatch(authActions.shopOpenTime())
        })
  }

  const timeFormat = useCallback((a) => {
    if(Number(a) !== 0){
    let hour = parseInt(a.substr(0, 2));
    let minute = parseInt(a.substr(2, 2));
    return hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0')
    }
  },[])

  const reverseTimeFormat = (timeStr) => {
    let result = timeStr?.replace(/:/g, '');
    return result
  }

  const changeTime = (e, type) => {
    setUpdateData({...updateData, [type] : e.target.value})
    // setTimeout(() => {
    //   window.location.reload();
    // }, 2000); 
  }

  return (
    <Grid container className="shop_timing">
      <Grid item xs={12} className="shop_timing_container">
      <Grid>
          <form className={classes.container} noValidate>
            <TextField
              id="time"
              label="Set Open Time"
              type="time"
              value={updateData.shopOpenTime? updateData.shopOpenTime : timeFormat(shopOpenTime?.toString())} 
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => changeTime(e, 'shopOpenTime')}
            />
          </form>
        </Grid>
        <Grid>
          <form className={classes.container} noValidate>
            <TextField
              id="time"
              label=" Special Address Open Time"
              type="time"
              value={updateData.specailAddressOpenTime? updateData.specailAddressOpenTime : timeFormat(specailAddressOpenTime?.toString())} 
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => changeTime(e, 'specailAddressOpenTime')}
            />
          </form>
        </Grid>
        <Grid>
          <form className={classes.container} noValidate>
            <TextField
              id="time"
              label="Set Close Time"
              type="time"
              value={updateData.shopCloseTime? updateData.shopCloseTime : timeFormat(shopCloseTime?.toString())} 
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => changeTime(e, 'shopCloseTime')}
            />
          </form>
        </Grid>
      </Grid>
      <Grid className='w-100'>
        <Grid className="time_button">
      <Button
        variant="contained"
        color="primary"
        onClick={updateShoptime}
      >
        Update Time
      </Button>   
      </Grid>
      </Grid>
    </Grid>
  )
}

export default ShopTiming