import './App.css';
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import {
  Login,
  ManageProductsPage,
  ManageCategoriesPage,
  SingleProductPage,
  // AppointmentsPage,
  PromotionsPage,
  FhlOrder,
  User,
  ShopTiming,
  // TrucksPage,
  // AddTruck,
  // EditTruck,
  NotificationPage,
  Employees,
  // Orders
} from './containers';
import { isLoggedIn } from "./utils"
import CustomDrawer from './components/common/CommonDrawer';
import CommonModal from "./components/common/CommonModal"

import { Component, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from "./redux/actions/auth"
import Brands from './containers/Brands';
import BannerTiming from './containers/ManagerBanner';
import Strains from './containers/ManageStrains';
import MainStrains from './containers/ManageMainStrains';
import ManageBanners from './containers/ManageBanners';
import ManageCategoriesBanners from './containers/ManageCategoriesBanners';

var id = null

function Routes() {
  const dispatch = useDispatch()

  const [lastSync, setLastSync] = useState(10)

  const { lastSyncDate } = useSelector(state => state.auth)

  useEffect(() => {
    id = setInterval(timer, 900000);

    return () => {
      clearInterval(id)
    }
  }, [])

  function timer() {
    dispatch(authActions.getLastSyncDate())
  }


  return (
    <Switch>
      <PublicRoute path="/login" component={Login} />
      <PrivateRoute path="/manage_products/:id" component={SingleProductPage} />
      <PrivateRoute path="/manage_products" component={ManageProductsPage} />
      <PrivateRoute path="/manage_category/" component={ManageCategoriesPage} />
      {/* <PrivateRoute path="/service_areas" component={AppointmentsPage} /> */}
      <PrivateRoute path="/promotions" component={PromotionsPage} />
      {/* <PrivateRoute path="/orders" component={Orders} /> */}
      {/* <PrivateRoute path="/terminal/add" component={AddTruck} /> */}
      {/* <PrivateRoute path="/terminal/edit/:id" component={EditTruck} /> */}
      {/* <PrivateRoute path="/terminals" component={TrucksPage} /> */}
      <PrivateRoute path="/notification" component={NotificationPage} />
      <PrivateRoute path="/brands" component={Brands} />
      <PrivateRoute path="/users" component={User} />
      <PrivateRoute path="/shop_timing" component={ShopTiming} />
      <PrivateRoute path="/fhl_order" component={FhlOrder} />
      <PrivateRoute path="/settings" component={BannerTiming} />
      <PrivateRoute path="/manage_employees" component={Employees} />
      <PrivateRoute path="/manage_strains" component={Strains} />
      <PrivateRoute path="/manage_mainStrain" component={MainStrains} />
      <PrivateRoute path="/manage_banners" component={ManageBanners} />
      <PrivateRoute path="/manage_categories_banners" component={ManageCategoriesBanners} />

      <Route path="/">
        <Redirect to="/manage_products" />
      </Route>

    </Switch>
  );
}

export default Routes;


const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Component {...props} />
      )
      }
    />
  )
}


const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          isLoggedIn() ? (
            <>
              <CustomDrawer >
                <Component {...props} />
              </CustomDrawer>

              <CommonModal />
            </>
          )
            :
            <Redirect to={`/login?redirect=${props.location.pathname}`} />
        )
      }}
    />
  )
}