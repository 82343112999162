import { toast } from 'react-toastify';
import { addOrUpdateStrainAPI, deleteStrainAPI, getAllInfoEffectAPI, getStrainsAPI, uploadImageAPI, getFlavorsAPI, getMainStrainsAPI, updateMainStrainAPI } from "../api/strains";

export const GET_ALL_STRAINS = 'GET_ALL_STRAINS';
export const GET_ALL_STRAINS_SUCCESS = 'GET_ALL_STRAINS_SUCCESS'
export const GET_ALL_STRAINS_FAILED = 'GET_ALL_STRAINS_FAILED'

export function getAllStrains(data) {
    return dispatch => {
        dispatch({ type: GET_ALL_STRAINS })
        return new Promise((resolve, reject) => {
            getStrainsAPI(data).then(res => {
                dispatch({ type: GET_ALL_STRAINS_SUCCESS, payload: res })
                resolve(res)
            }).catch((err) => {
                dispatch({ type: GET_ALL_STRAINS_FAILED })
                reject(err)
            })
        })
    }
}

export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS'
export const ADD_EMPLOYEE_FAILED = 'ADD_EMPLOYEE_FAILED'

export function addOrUpdateStrain(data, id) {
    return dispatch => {
        dispatch({ type: ADD_EMPLOYEE })
        return new Promise((resolve, reject) => {
            addOrUpdateStrainAPI(data, id).then(res => {
                dispatch({ type: ADD_EMPLOYEE_SUCCESS, payload: res })
                toast.success(res.message)
                resolve(res)
            }).catch((err) => {
                dispatch({ type: ADD_EMPLOYEE_FAILED })
                reject(err)
            })
        })
    }
}

export const DELETE_STRAIN = 'DELETE_STRAIN';
export const DELETE_STRAIN_SUCCESS = 'DELETE_STRAIN_SUCCESS'
export const DELETE_STRAIN_FAILED = 'DELETE_STRAIN_FAILED'

export function deleteStrain(id) {
    return dispatch => {
        dispatch({ type: DELETE_STRAIN })
        return new Promise((resolve, reject) => {
            deleteStrainAPI(id).then(res => {
                dispatch({ type: DELETE_STRAIN_SUCCESS, payload: res })
                toast.success(res.message)
                resolve(res)
            }).catch((err) => {
                dispatch({ type: DELETE_STRAIN_FAILED })
                reject(err)
            })
        })
    }
}

export const INFO_EFFECTS = 'INFO_EFFECTS';
export const INFO_EFFECTS_SUCCESS = 'INFO_EFFECTS_SUCCESS'
export const INFO_EFFECTS_FAILED = 'INFO_EFFECTS_FAILED'

export function getAllInfoEffect() {
    return dispatch => {
        dispatch({ type: INFO_EFFECTS })
        return new Promise((resolve, reject) => {
            getAllInfoEffectAPI().then(res => {
                dispatch({ type: INFO_EFFECTS_SUCCESS, payload: res })
                toast.success(res.message)
                resolve(res)
            }).catch((err) => {
                dispatch({ type: INFO_EFFECTS_FAILED })
                reject(err)
            })
        })
    }
}

export function uploadStrainImage(data) {
    return dispatch => {
        // dispatch({ type: INFO_EFFECTS })
        return new Promise((resolve, reject) => {
            uploadImageAPI(data).then(res => {
                // dispatch({ type: INFO_EFFECTS_SUCCESS, payload: res })
                toast.success(res.message)
                resolve(res)
            }).catch((err) => {
                // dispatch({ type: INFO_EFFECTS_FAILED })
                reject(err)
            })
        })
    }
}

// Get all Flavour 
export const GET_ALL_FLAVORS = 'GET_ALL_FLAVORS';
export const GET_ALL_FLAVORS_SUCCESS = 'GET_ALL_FLAVORS_SUCCESS'
export const GET_ALL_FLAVORS_FAILED = 'GET_ALL_FLAVORS_FAILED'

export function getAllFlavors(data) {
    return dispatch => {
        dispatch({ type: GET_ALL_FLAVORS })
        return new Promise((resolve, reject) => {
            getFlavorsAPI(data).then(res => {
                dispatch({ type: GET_ALL_FLAVORS_SUCCESS, payload: res })
                resolve(res)
            }).catch((err) => {
                dispatch({ type: GET_ALL_FLAVORS_FAILED })
                reject(err)
            })
        })
    }
} 

// Main Strain API

export const GET_ALL_MAIN_STRAINS = 'GET_ALL_MAIN_STRAINS';
export const GET_ALL_MAIN_STRAINS_SUCCESS = 'GET_ALL_MAIN_STRAINS_SUCCESS'
export const GET_ALL_MAIN_STRAINS_FAILED = 'GET_ALL_MAIN_STRAINS_FAILED'

export function getAllMainStrains(data) {
    return dispatch => {
        dispatch({ type: GET_ALL_MAIN_STRAINS })
        return new Promise((resolve, reject) => {
            getMainStrainsAPI(data).then(res => {
                dispatch({ type: GET_ALL_MAIN_STRAINS_SUCCESS, data: res })
                resolve(res)
            }).catch((err) => {
                dispatch({ type: GET_ALL_MAIN_STRAINS_FAILED })
                reject(err)
            })
        })
    }
}

export const ADD_MAIN_EMPLOYEE = 'ADD_MAIN_EMPLOYEE';
export const ADD_MAIN_EMPLOYEE_SUCCESS = 'ADD_MAIN_EMPLOYEE_SUCCESS'
export const ADD_MAIN_EMPLOYEE_FAILED = 'ADD_MAIN_EMPLOYEE_FAILED'

export function updateMainStrain(data) {
    return dispatch => {
        dispatch({ type: ADD_MAIN_EMPLOYEE })
        return new Promise((resolve, reject) => {
            updateMainStrainAPI(data).then(res => {
                dispatch({ type: ADD_MAIN_EMPLOYEE_SUCCESS, data: res })
                toast.success(res.message)
                resolve(res)
            }).catch((err) => {
                dispatch({ type: ADD_MAIN_EMPLOYEE_FAILED })
                reject(err)
            })
        })
    }
}