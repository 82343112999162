import React, { useCallback } from "react";
import { makeStyles } from '@material-ui/core/styles';

import { useDropzone } from 'react-dropzone'


function CustomDropDown(props) {
    const classes = useStyles();

    const {
        setFile = () => { },
        id = "",
        src,
        files = [],
        accept = '.jpeg,.jpg,.png,.pdf,.xls,.doc,.docx,.svg,.gif',
        imageProps
    } = props


    const onDrop = useCallback((acceptedFiles = []) => {
        // Do something with the files  
        setFile(acceptedFiles)

    }, [...files])


    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: accept })

    return (
        <div className="browse-list" id={id}>
            <div className="drop-files" {...getRootProps()}>
                <input {...getInputProps()} />
                {/* <div className="folder-icon-sec">
                    <img src="/images/folder-icon.svg" />
                </div> */}
                <h4>Drop your files here or <span>browse</span></h4>
                <p>Supports: JPG, PNG</p>

                {

                    src && <div className="custom-dropzone-image-wrapper">
                        <div className="image-custom-dropzone-div">
                            <img src={src || ""} alt="drop-image" {...imageProps} />
                        </div>
                    </div>

                }
            </div>
        </div>
    )

}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center"
    },

}));



export default CustomDropDown;