import { apiGet, apiPost } from '../../utils';

export function loginAPI(data) {
    return apiPost('api/v1/user/admin/login', data);
}

export function getLastSyncDateAPI() {
    return apiGet('api/v1/admin/products/getLastSync');
}

// API's for Open and close shop timings:
export function shopOpenTimeAPI() {
    return apiGet('api/v1/shop/get/variables');
}

export function shopUpdateTimeAPI(data) {
    return apiPost('api/v1/shop/update/variables', data);
}