import React, { useEffect, useState } from 'react';
import { Grid, Button, Switch, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import * as  bannerActions from '../../redux/actions/banner';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    scrollBehavior: 'smooth',
  },
}));

const BannerTiming = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const bannerTimings = useSelector((state) => state?.banner?.allBanner);
  const fhlData = useSelector(state => state?.banner?.fhlData)
  const [updateBannerTiming, setUpdatedBannerTiming] = useState(bannerTimings);
  const [fhlStatus, setFhlStatus] = useState(false)


  useEffect(()=>{
    setFhlStatus(fhlData?.isActive)
  },[fhlData])

  useEffect(() => {
    dispatch(bannerActions.getBannerTiming());
  }, []);
  
  useEffect(() => {
    dispatch(bannerActions.getFhlStatus())
  }, []);
 
  useEffect(() => {
    setUpdatedBannerTiming(bannerTimings);
  }, [bannerTimings]);


  const handleTimingChange = (field, value) => {
    if (field === 'bannerTimings') {
      setUpdatedBannerTiming(value);
    }
  };

  const updateTiming = () => {
    dispatch(bannerActions.updateBannerTiming({ bannerTimings: updateBannerTiming }));
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const onChangeFhlStatus = (e) => {
    // setFhlStatus(e.target.checked)
    setFhlStatus(!fhlStatus)
  }

  const updateFhlStatus = () => {
    dispatch(bannerActions.updateFhlStatus({ isActive: fhlStatus })).then(() => {
      toast.success('FHL status updated successfully')
    }).catch(() => {  })
  }


  return (
    <>
      <Grid container className="shop_timing">
        <Grid item xs={12} className="shop_timing_container ">
          <Grid>
            <form className={classes.container} noValidate>
              <TextField
                id="banner-timings"
                label="Banner Timing"
                type="number"
                value={updateBannerTiming?.bannerTimings}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}

                onChange={(e) => {
                  console.log("New value:", e.target.value);
                  handleTimingChange('bannerTimings', e.target.value);
                }}
              />
            </form>
          </Grid>
        </Grid>
        <Grid className="w-100 mb-2">
          <Grid className="time_button">
            <Button variant="contained" color="primary" className='abc' onClick={updateTiming}>
              Update Limit
            </Button>
          </Grid>
        </Grid>


        <Grid item xs={12} className="shop_timing_container">
          <Grid className='fhl_status'>
            <Typography>FHL Status</Typography>
            <Switch
              checked={fhlStatus}
              onChange={onChangeFhlStatus}
              color="primary"
              name="fhlStatus"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Grid>
        </Grid>
        <Grid className="w-100">
          <Grid className="time_button">
            <Button variant="contained" color="primary" className='abc'
              onClick={updateFhlStatus}
            >
              Update Status
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BannerTiming;
