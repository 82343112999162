import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom"
import { Button, Typography, CircularProgress, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash"
import CustomInput from '../../components/common/CustomInput';
import { validateUserData } from '../../utilities/validations/user';
import * as actions from "../../redux/actions/user"
import * as commonActions from "../../redux/actions/common"

const initialUser = {
    name: "",
    email: "",
    password: "",
}

function UserModal(props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch()

    const { modalData } = useSelector(state => state.common)
    const { adminLoader = false } = useSelector(state => state.user)

    const { title = "Add", data: editData = {} } = modalData

    const [user, setUser] = useState({ ...initialUser })
    const [errors, setErrors] = useState({ ...initialUser })

    useEffect(() => {
        if (!_.isEmpty(editData)) {
            setUser(editData)
        }
    }, [])

    const onChangehandler = (e) => {
        const { name = '', value = '' } = e.target || {}
        setUser({ ...user, [name]: value })
    }

    const addUser = () => {
        const { errors, isValid } = validateUserData(user)
        setErrors(errors);

        if (isValid) {
            dispatch(actions.addUser(user, (user._id || ''))).then(res => {
                dispatch(actions.getAllAdmin())
                dispatch(commonActions.closeCustomModalDialog())
            })
        }
    }

    return (
        <Grid container component="main" className={`${classes.root} add-zipcode-modal-container`}>
            <Grid item xs={12} className="pt-2">
                <Typography variant="h4" className="d-flex jc-ctr">{title} User</Typography>
            </Grid>

            <Grid item xs={12} className="add-category-input">
                <Grid item xs={12}>
                    <CustomInput
                        name="name"
                        value={user.name || ""}
                        onChange={onChangehandler}
                        label="Name"
                        className="w-100"
                        margin="normal"
                        error={errors.name}
                    />
                </Grid>
                <Grid item xs={12} >
                    <CustomInput
                        name="email"
                        value={user.email || ""}
                        onChange={onChangehandler}
                        label="Email"
                        className="w-100"
                        margin="normal"
                        error={errors.email}
                    />
                </Grid>
                <Grid item xs={12} >
                    <CustomInput
                        type="password"
                        name="password"
                        value={user.password || ""}
                        onChange={onChangehandler}
                        label="Password"
                        className="w-100 "
                        margin="normal"
                        error={errors.password}
                    />
                </Grid>
                <Grid item xs={12} className="d-flex jc-ctr add-button">
                    <Button onClick={addUser} variant="contained" color="primary" fullWidth>{adminLoader ? <CircularProgress size={22} style={{ color: "white" }} /> : 'Submit'}</Button>
                </Grid>
            </Grid>

        </Grid >
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));


export default UserModal