import { toast } from 'react-toastify';
import {
    getAllOrdersAPI,
    getFhlOrderAPI,
    updateFhlOrderAPI
} from "../api/orders";


export const GET_ALL_ORDERS = 'GET_ALL_ORDERS';
export const GET_ALL_ORDERS_SUCCESSFULL = 'GET_ALL_ORDERS_SUCCESSFULL';
export const GET_ALL_ORDERS_FAILED = 'GET_ALL_ORDERS_FAILED';

export function getAllOrders(data) {
    return dispatch => {
        dispatch({
            type: GET_ALL_ORDERS
        })
        return new Promise((resolve, reject) => {
            getAllOrdersAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_ALL_ORDERS_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_ALL_ORDERS_FAILED
                    })
                    return reject(err)
                })
        })
    }
}


export const GET_FHL_ORDER = 'GET_FHL_ORDER';
export const GET_FHL_ORDER_SUCCESS = 'GET_FHL_ORDER_SUCCESS';
export const GET_FHL_ORDER_FAILURE = 'GET_FHL_ORDER_FAILURE';

export function getFhlOrder(data) {
    return dispatch => {
        dispatch({
            type: GET_FHL_ORDER
        })
        return new Promise((resolve, reject) => {
            getFhlOrderAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_FHL_ORDER_SUCCESS,
                        data: res
                    })
                  
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_FHL_ORDER_FAILURE
                    })
                    return reject(err)
                })
        })
    }
}

export const UPDATE_FHL_ORDER = 'UPDATE_FHL_ORDER';
export const UPDATE_FHL_ORDER_SUCCESS = 'UPDATE_FHL_ORDER_SUCCESS';
export const UPDATE_FHL_ORDER_FAILURE = 'UPDATE_FHL_ORDER_FAILURE';

export function updateFhlOrder(data) {
    return dispatch => {
        dispatch({
            type: UPDATE_FHL_ORDER
        })
        return new Promise((resolve, reject) => {
            updateFhlOrderAPI(data)
                .then((res) => {
                    dispatch({
                        type: UPDATE_FHL_ORDER_SUCCESS,
                        data: res
                    })
                    toast.success(res.message)
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_FHL_ORDER_FAILURE
                    })
                    return reject(err)
                })
        })
    }
}