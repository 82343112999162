import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/strains";
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import * as commonActions from "../../redux/actions/common"

import {
    Grid,
    Button,
    TableCell,
    Box,
    Link,
    Chip
} from "@material-ui/core"
import CommonLoading from '../../components/common/CommonLoading'
import CustomTable from '../../components/common/CustomTable';

function Strains() {

    const history = useHistory()
    const dispatch = useDispatch()

    const {
        allStrains = [],
        isLoading = false,
    } = useSelector(state => state.strains) || {}

    const [pagination, setPagination] = useState({
        limit: 200,
        skip: 0,
    });


    useEffect(() => {
        dispatch(actions.getAllStrains(pagination));        
    }, [pagination]);


    const handleNextPage = () => {
        setPagination(prevPagination => ({
            ...prevPagination,
            skip: prevPagination.skip + prevPagination.limit,
        }));
    };
    useEffect(() => {
        dispatch(actions.getAllInfoEffect())
        dispatch(actions.getAllFlavors())
    }, [])

    const handleConfirmation = (id) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Are you sure you want to Delete?",
            confirm: () => { onDeleteAdmin(id) },
            cancel: () => { }
        }, "ConfirmationModal"))
    }

    const onDeleteAdmin = (id) => {
        dispatch(actions.deleteStrain(id)).then(res => {
        })
        dispatch(actions.getAllStrains(pagination))
    }

    const onEditAdmin = (item) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Edit",
            data: item
        }, "AddStrainModal"))

    }
    return (
        <Grid container component="main" className="admin-container">
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <div className="custom-button-div">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => { dispatch(commonActions.openCustomModalDialog({}, "AddStrainModal")) }}
                            >Add Strain</Button>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mt={3}>
                            <CustomTable
                                tableHeading={["S.no", "Strain Name", "Strain URL", "Meta Title", "Meta Description", "Created By", "Created Date", "Actions"]}
                                rowsData={allStrains.map((item, index) => (
                                    <StrainTableRow
                                        key={item._id}
                                        item={item}
                                        index={index}
                                        onEditAdmin={onEditAdmin}
                                        onDeleteAdmin={handleConfirmation}
                                        handleNextPage={handleNextPage}
                                    />
                                ))}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <CommonLoading isLoading={isLoading} />
        </Grid>
    )
}

export default Strains


const StrainTableRow = ({ item, index, onDeleteAdmin, onEditAdmin }) => {
    return (
        <>
            <TableCell>{index + 1}</TableCell>
            <TableCell className='editor-content'>
                <div style={{display:'flex',alignItems:'center',gap:10}}>
                    <p>{item?.strainName}</p>
                    <span>{!item?.status && <Chip label="Draft" variant='default' />}</span>
                </div>

            </TableCell>
            {/* <TableCell>{item?.status ? <Chip label="Publish" variant='outlined' style={{color:'white',background:'#2e7d32'}} /> : <Chip label="Draft" variant="outlined" style={{color:'white',background:'rgb(211, 47, 47)'}} />}</TableCell> */}
            <TableCell><Link href={item?.canonical} target='_blank'>{item?.canonical}</Link></TableCell>
            <TableCell>{item?.metaTitle}</TableCell>
            <TableCell>{item?.metaDescription}</TableCell>
            {/* <TableCell className='editor-content'>
                <div dangerouslySetInnerHTML={{
                    __html: item?.description.length > 20 ?  item?.description.substring(0, 50) + "..." :  item?.description
                }}>
                </div>
            </TableCell> */}
            <TableCell>{item?.createdBy}</TableCell>
            <TableCell>{new Date(item?.createdDate).toLocaleDateString()}</TableCell>
            <TableCell>
                <div>
                    <EditIcon className="cursor-pointer" onClick={() => onEditAdmin(item)} />
                    <DeleteOutlineIcon className="cursor-pointer" onClick={() => onDeleteAdmin(item._id)} />
                </div>
            </TableCell>
        </>
    );
};
