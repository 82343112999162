import {
    GET_ALL_ORDERS,
    GET_ALL_ORDERS_FAILED,
    GET_ALL_ORDERS_SUCCESSFULL,
    GET_FHL_ORDER,
    GET_FHL_ORDER_SUCCESS,
    GET_FHL_ORDER_FAILURE,
    UPDATE_FHL_ORDER,
    UPDATE_FHL_ORDER_SUCCESS,
    UPDATE_FHL_ORDER_FAILURE
} from '../actions/orders';

const initialState = {
    isLoading: false,
    allOrders: {},
    fhlOrder:{},
}

export default function orders(state = { ...initialState }, action = {}) {
    switch (action.type) {
        //Get all orders
        case GET_ALL_ORDERS:
            return { ...state, isLoading: true }

        case GET_ALL_ORDERS_SUCCESSFULL:
            return { ...state, isLoading: false, allOrders: action.data }

        case GET_ALL_ORDERS_FAILED:
            return { ...state, isLoading: false }

        case GET_FHL_ORDER:
                return { ...state, isLoading: true }
    
        case GET_FHL_ORDER_SUCCESS:
                return { ...state, isLoading: false, fhlOrder: action?.data }
    
        case GET_FHL_ORDER_FAILURE:
                return { ...state, isLoading: false }

        case UPDATE_FHL_ORDER:
                return { ...state, isLoading: true }
    
        case UPDATE_FHL_ORDER_SUCCESS:
                return { ...state, isLoading: false }
    
        case UPDATE_FHL_ORDER_FAILURE:
                return { ...state, isLoading: false }
        

        default:
            return state
    }
}

