import { isEmpty } from "lodash";


export function validateNotificationForm(data = {}) {
    let errors = {};
    let isDrawserOpen = false;

    if (!data.message) {
        errors.message = "Message is required!";
    }

    if (!data.title) {
        errors.title = "Title is required!";
    }

    if (!data.deviceType) {
        errors.deviceType = "Device Type is required!";
    }
    if (!data.type) {
        errors.type = "Type is required!";
    }
    if (data.type != "GENERAL" && isEmpty(data.selectedId)) {
        let idError = getSelectedIdError(data.type)
        errors.selectedId = idError + " is requried!"
    }

    if (data.deviceType === "USER") {
        if (!data?.userId.length > 0) {
            errors.userId = "User is required!";
        }
        // if (!data?.userId?._id) {
        //     errors.userId = "User is required!";
        // }
    }

    if (data.title && data.message && data.deviceType == "USER" && data.type) {
        if (!data?.userId.length > 0) {
            isDrawserOpen = true
        }
    }
    return {
        isValid: isEmpty(errors),
        errors,
        isDrawserOpen
    }
}

function getSelectedIdError(type) {
    switch (type) {
        case "PRODUCT":
            return 'Product' || ''
        case "CATEGORY":
            return 'Category' || ''
        case "GROUP":
            return 'Group' || ''
        // case "GENERAL":
        //     return 'General' || ''
        default:
            return 'Id'
    }
}
