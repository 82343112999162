import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom"
import { Button, Typography, CircularProgress, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash"
import CustomInput from '../../components/common/CustomInput';
import * as actions from "../../redux/actions/employee"
import * as commonActions from "../../redux/actions/common"
import { validateEmployeeData } from '../../utilities/validations/employee';


const initialEmployee = {
    name: "",
    email: "",
    phone: "",
    // address: "",
    // source:"",
}

function EmployeeModal(props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch()

    const { modalData } = useSelector(state => state.common)
    const { isLoading = false } = useSelector(state => state.employee)
    const [phoneError, setPhoneError] = useState("");
    const { title = "Add", data: editData = {} } = modalData

    const [user, setUser] = useState({ ...initialEmployee })
    const [errors, setErrors] = useState({ ...initialEmployee })
    const isEditdata = !_.isEmpty(editData)

    
    useEffect(() => {
        if (isEditdata) {
            const formattedPhone = editData.phone || "";
            setUser({ ...editData, phone: formattedPhone });
        }
    }, [isEditdata, editData]);
    
    const onChangehandler = (e) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
        if (name === "phone") {
            setPhoneError("");
        }
    };
    

    const addUser = () => {
        const { errors: validationErrors, isValid: isDataValid } = validateEmployeeData(user);
        setErrors(validationErrors);
    
        const isPhoneValid = validatePhoneNumber(user.phone);
        if (!isPhoneValid) {
            setPhoneError("Invalid phone number");
        } else {
            setPhoneError("");
        }
    
        let phoneToSend = user.phone;
        
        if (isDataValid && isPhoneValid) {
            // Remove the country code if title is "Edit"
            if (title === "Edit") {
                // Remove the +1 country code if present
                phoneToSend = phoneToSend.replace(/^\+1/, "");
            }
            
            const userForPayload = { ...user, phone: phoneToSend };
            dispatch(actions.addEmployee(userForPayload, (user._id || ''))).then(res => {
                dispatch(actions.getAllEmployee());
                dispatch(commonActions.closeCustomModalDialog());
            });
        }
    };
    
    
    
    
    const validatePhoneNumber = (phone) => {
        const numericPhone = phone.replace(/\D/g, ""); // Extract numeric digits only
        return numericPhone.length > 8 && numericPhone.length <= 11;
    };
    
    
    console.log("user", user);


    return (
        <Grid container component="main" className={`${classes.root} add-zipcode-modal-container`}>
            <Grid item xs={12} className="pt-2">
                <Typography variant="h4" className="d-flex jc-ctr">{title} Employee</Typography>
            </Grid>

            <Grid item xs={12} className="add-category-input">
                <Grid item xs={12}>
                    <CustomInput
                        name="name"
                        value={user.name || ""}
                        onChange={onChangehandler}
                        label="Name"
                        className="w-100"
                        margin="normal"
                        error={errors.name}
                    />
                </Grid>
                <Grid item xs={12} >
                    <CustomInput
                        disabled={isEditdata}
                        name="email"
                        value={user?.email || ""}
                        onChange={onChangehandler}
                        label="Email"
                        className="w-100"
                        margin="normal"
                        error={errors.email}
                    />
                </Grid>
                <Grid item xs={12}>
                <PhoneInput
                    country="us"
                    name="phone"
                    value={user?.phone || ""}
                    countryCodeEditable="false"
                    onChange={(phone) => {
                        setUser({ ...user, phone });
                        setPhoneError("");
                    }}
                    onBlur={() => {
                        const isValid = validatePhoneNumber(user.phone);
                        setPhoneError(isValid ? "" : "Invalid phone number");
                    }}
                    inputProps={{
                        name: 'phone',
                        required: true,
                    }}
                    inputStyle={{width: "100%",height: "55px",}}
                    
                />

                    {phoneError && <Typography variant="caption" color="error">{phoneError}</Typography>}
                </Grid>


                {/* <Grid item xs={12}>
                    <CustomInput
                        name="address"
                        value={user.address || ""}
                        onChange={onChangehandler}
                        label="Address"
                        className="w-100"
                        margin="normal"
                        error={errors.address}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomInput
                        name="source"
                        value={user.source || ""}
                        onChange={onChangehandler}
                        label="source"
                        className="w-100"
                        margin="normal"
                        error={errors.source}
                    />
                </Grid> */}
                <Grid item xs={12} className="d-flex jc-ctr add-button">
                    <Button onClick={addUser} variant="contained" color="primary" fullWidth>{isLoading ? <CircularProgress size={22} style={{ color: "white" }} /> : 'Submit'}</Button>
                </Grid>
            </Grid>

        </Grid >
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));


export default EmployeeModal