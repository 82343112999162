import { getLastSyncDateAPI, loginAPI, shopOpenTimeAPI, shopUpdateTimeAPI } from "../api/auth";
import { toast } from "react-toastify"

export const SET_USER = 'SET_USER';

export function setUser(user) {
    return dispatch => {
        dispatch({
            type: SET_USER,
            user
        })
    }
}


export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const LOGIN_SUCCESSFULL = 'LOGIN_SUCCESSFULL';
export const LOGIN_FALED = 'LOGIN_FALED';

export function login(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_LOGIN
        })
        return new Promise((resolve, reject) => {
            loginAPI(data)
                .then((res) => {
                    //User Logged in sucessfully 
                    dispatch({
                        type: LOGIN_SUCCESSFULL,
                        user: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: LOGIN_FALED
                    })
                    toast.error(err || 'Something went wrong')
                    return reject(err)
                })
        })
    }
}

export const LOGOUT_USER = 'LOGOUT_USER';

export function logoutUser(user) {
    return dispatch => {
        dispatch({
            type: LOGOUT_USER,
            user
        })
    }
}


export const GET_LAST_SYNC_DATE = 'GET_LAST_SYNC_DATE';
export const GET_LAST_SYNC_DATE_SUCCESSFULL = 'GET_LAST_SYNC_DATE_SUCCESSFULL';
export const GET_LAST_SYNC_DATE_FAILED = 'GET_LAST_SYNC_DATE_SUCCESSFULL';

export function getLastSyncDate(data) {
    return dispatch => {
        dispatch({
            type: GET_LAST_SYNC_DATE
        })
        return new Promise((resolve, reject) => {
            getLastSyncDateAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_LAST_SYNC_DATE_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_LAST_SYNC_DATE_FAILED
                    })
                    return reject(err)
                })
        })
    }
}

// Set API's for Open and close shop time

export const OPEN_SHOP_TIME = 'OPEN_SHOP_TIME';
export const OPEN_SHOP_TIME_SUCCESSFULL = 'OPEN_SHOP_TIME_SUCCESSFULL';
export const OPEN_SHOP_TIME_FAILED = 'OPEN_SHOP_TIME_FAILED';

export function shopOpenTime(data) {
    return dispatch => {
        dispatch({
            type: OPEN_SHOP_TIME
        })
        return new Promise((resolve, reject) => {
            shopOpenTimeAPI(data)
                .then((res) => {
                    //Shop Open Time Updated Successfully
                    dispatch({
                        type: OPEN_SHOP_TIME_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: OPEN_SHOP_TIME_FAILED
                    })
                    toast.error(err || 'Something went wrong')
                    return reject(err)
                })
        })
    }
}

export const UPDATE_SHOP_TIME = 'UPDATE_SHOP_TIME';
export const UPDATE_SHOP_TIME_SUCCESSFULL = 'UPDATE_SHOP_TIME_SUCCESSFULL';
export const UPDATE_SHOP_TIME_FAILED = 'UPDATE_SHOP_TIME_FAILED';

export function shopUpdateTime(data) {
    return dispatch => {
        dispatch({
            type: UPDATE_SHOP_TIME
        })
        return new Promise((resolve, reject) => {
            shopUpdateTimeAPI(data)
                .then((res) => {
                    //Shop UPDATE Time Updated Successfully
                    dispatch({
                        type: UPDATE_SHOP_TIME_SUCCESSFULL,
                        updatedData: res
                    })
                    toast.success(res.message || "Updated successfully!")
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_SHOP_TIME_FAILED
                    })
                    toast.error(err || 'Something went wrong')
                    return reject(err)
                })
        })
    }
}