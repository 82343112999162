import React, { useState, useEffect, useMemo } from 'react';
import 'react-phone-input-2/lib/style.css';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, CircularProgress, Grid, FormHelperText, FormControl, FormGroup } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash"
import CustomInput from '../../components/common/CustomInput';
import * as actions from "../../redux/actions/strains"
import * as commonActions from "../../redux/actions/common"
import CommonEditor from '../../components/common/CommonEditor';
import { validateMainStrainData } from '../../utilities/validations/mainStrain';


function MainStrains(props) {
    const classes = useStyles();
    const dispatch = useDispatch()

    const { modalData } = useSelector(state => state.common)
    const { isLoading = false, infoEffects, allFlavorsData, allMainStrains } = useSelector(state => state.strains)
    const { title = "Add", data: editData = {} } = modalData
    const [strainData, setStrainData] = useState({ ...allMainStrains})
    const [errors, setErrors] = useState({})

    const isEditdata = useMemo(() => {
        return !_.isEmpty(editData)
    }, [editData])

    useEffect(() => {   
        dispatch(actions.getAllMainStrains())
    },[])

    useEffect(() => {
            setStrainData({ ...allMainStrains });
    }, [ allMainStrains]);

    const onChangehandler = (e) => {
        const { name, value } = e.target;
        setStrainData({ ...strainData, [name]: value });
    };

    const onEditorChangehandler = (value, name) => {
        setStrainData({ ...strainData, [name]: value });
    };


    const addMainStrain = () => {
        const { errors: validationErrors, isValid: isDataValid } = validateMainStrainData(strainData);
        if (!isDataValid) {
            setErrors(validationErrors);
            return;
        }
        setErrors({})
        const userForPayload = { ...strainData };
        dispatch(actions.updateMainStrain(userForPayload)).then(res => {
            dispatch(actions.getAllMainStrains());
            dispatch(commonActions.closeCustomModalDialog());
        });
    };

    const imageChangeHandler = (e) => {
        let file = e.target.files[0]
        const formData = new FormData();
        formData.append('filePath', file)
        dispatch(actions.uploadStrainImage(formData)).then((res) => {
            // console.log("res",res)
            setStrainData({ ...strainData, image: res?.fileUrl })
        });
    }

    return (
        <Grid container spacing={4} component="main" className={`${classes.root} add-zipcode-modal-container`}>
            <Grid item xs={12} className="add-category-input">
                <Grid item xs={12} className="txt-left">
                    <CustomInput
                        name="metaTitle"
                        value={strainData.metaTitle || ""}
                        onChange={onChangehandler}
                        label="Meta Title"
                        className="w-100"
                        margin="normal"
                        error={errors.metaTitle}
                    />
                </Grid>
                <Grid item xs={12} className="txt-left" >
                    <CustomInput
                        name="metaDescription"
                        value={strainData.metaDescription || ""}
                        onChange={onChangehandler}
                        label="Meta Description"
                        className="w-100"
                        margin="normal"
                        error={errors.metaDescription}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormHelperText style={{ fontSize: '14px' }}>Description</FormHelperText>
                        <CommonEditor onChange={onEditorChangehandler} id={"description"} value={allMainStrains.description} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} >
                        <FormControl fullWidth>
                            <FormHelperText style={{ fontSize: '14px' }}>Title</FormHelperText>
                            <CommonEditor onChange={onEditorChangehandler} id={"title"} value={allMainStrains.title} />
                        </FormControl>
                </Grid>
                <Grid item xs={12} >
                <Grid container className="mb-2 mt-2" >
                    <input type='file' name='filePath' onChange={imageChangeHandler} />
                    {
                        strainData?.image && <img style={{ height: 100, width: 200 }} src={strainData?.image} />
                    }
                    {
                        errors.image && <FormHelperText > <div className='error' >{errors.image} </div> </FormHelperText>
                    }
                    </Grid>
                </Grid>

                <Grid item xs={12} className="d-flex jc-ctr add-button">
                    <Button onClick={addMainStrain} variant="contained" color="primary" fullWidth>{isLoading ? <CircularProgress size={22} style={{ color: "white" }} /> : 'Submit'}</Button>
                </Grid>
            </Grid>

        </Grid >
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));


export default MainStrains