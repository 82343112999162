import {
    GET_ALL_STRAINS, GET_ALL_STRAINS_FAILED, GET_ALL_STRAINS_SUCCESS, INFO_EFFECTS_SUCCESS,
    GET_ALL_FLAVORS,
    GET_ALL_FLAVORS_SUCCESS,
    GET_ALL_FLAVORS_FAILED,
    GET_ALL_MAIN_STRAINS,
    GET_ALL_MAIN_STRAINS_SUCCESS,
    GET_ALL_MAIN_STRAINS_FAILED,
} from "../actions/strains";


const initialState = {
    allStrains: [],
    isLoading: false,
    infoEffects: [],
    allFlavorsData: [],
    allMainStrains: []
}

export default function employee(state = { ...initialState }, action = {}) {
    switch (action.type) {
        case GET_ALL_STRAINS: {
            return { ...state, isLoading: true }
        }
        case GET_ALL_STRAINS_SUCCESS: {
            const { strain = [], total = 0 } = action.payload || {}
            return { ...state, isLoading: false, allStrains: strain, }
        }
        case GET_ALL_STRAINS_FAILED: {
            return { ...state, isLoading: false }
        }
        case INFO_EFFECTS_SUCCESS: {
            return { ...state, infoEffects: action.payload }
        }
        //    case ADD_EMPLOYEE: {
        //        return { ...state, isLoading: true }
        //    }
        //    case ADD_EMPLOYEE_SUCCESS: {
        //        return { ...state, isLoading: false }
        //    }
        //    case ADD_EMPLOYEE_FAILED: {
        //        return { ...state, isLoading: false }
        //    }
        //    case DELETE_EMPLOYEE: {
        //        return { ...state, isLoading: true }
        //    }
        //    case DELETE_EMPLOYEE_SUCCESS: {
        //        return { ...state, isLoading: false }
        //    }
        //    case DELETE_EMPLOYEE_FAILED: {
        //        return { ...state, isLoading: false }
        //    }

        //get all category
        case GET_ALL_FLAVORS:
            return { ...state, isLoading: true }

        case GET_ALL_FLAVORS_SUCCESS:
            const { strainsFlavors = [], total = 0 } = action.payload || {}
            return { ...state, isLoading: false, allFlavorsData: strainsFlavors }

        case GET_ALL_FLAVORS_FAILED:
            return { ...state, isLoading: false }

        case GET_ALL_MAIN_STRAINS: {
            return { ...state, isLoading: true }
        }
        case GET_ALL_MAIN_STRAINS_SUCCESS: {
            return { ...state, isLoading: false, allMainStrains: action.data, }
        }
        case GET_ALL_MAIN_STRAINS_FAILED: {
            return { ...state, isLoading: false }
        }
        default: {
            return state
        }
    }
}
