import { apiDelete, apiGet, apiPost, apiPut } from '../../utils';

export function getBannerTimingAPI(data) {
    return apiGet('api/v1/banner/timings',data);
}

export function updateBannerTimingAPI(data) {
    return apiPost(`api/v1/banner/timings/update/variables`,data);
}


export function getBannersListsAPI(data) {
    return apiGet('api/v1/admin/banners',data);
}


export function updateBannerListAPI(data,id) {
    if (id) {
        return apiPut(`api/v1/admin/banners/${id}`, data);
    }
    return apiPost('api/v1/admin/banners', data);
}

export function deleteBannerAPI(id) {
    return apiDelete(`api/v1/admin/banners/${id}`)
}

export function updateFhlStatusAPI(data) {
    return apiPost('api/v1/fhl/update/status',data);
}

export function getFhlStatusAPI(data) {
    return apiGet('api/v1/fhl/status',data);
}