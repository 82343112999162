
import {
    ADD_CATEGORY,
    ADD_CATEGORY_FAILED,
    ADD_CATEGORY_SUCCESSFULL,
    GET_ALL_BLAZE_CATEGORY,
    GET_ALL_BLAZE_CATEGORY_FAILED,
    GET_ALL_BLAZE_CATEGORY_SUCCESSFULL,
    GET_ALL_CATEGORY,
    GET_ALL_CATEGORY_FAILED,
    GET_ALL_CATEGORY_SUCCESSFULL,
    GET_ALL_PRODUCTS,
    GET_ALL_PRODUCTS_FAILED,
    GET_ALL_PRODUCTS_SUCCESSFULL,
    GET_INFO_AND_EFFECT,
    GET_INFO_AND_EFFECT_FAILED,
    GET_INFO_AND_EFFECT_SUCCESSFULL,
    GET_SINGLE_PRODUCT_DETAILS,
    GET_SINGLE_PRODUCT_DETAILS_FAILED,
    GET_SINGLE_PRODUCT_DETAILS_SUCCESSFULL,
    UPDATE_CATEGORIES,
    UPDATE_CATEGORIES_FAILED,
    UPDATE_CATEGORIES_SUCCESSFULL,
    UPDATE_SINGLE_PRODUCT,
    UPDATE_SINGLE_PRODUCT_FAILED,
    UPDATE_SINGLE_PRODUCT_SUCCESSFULL,
    DELETE_CATEGORY,
    DELETE_CATEGORY_SUCCESSFULL,
    DELETE_CATEGORY_FAILED,
    CATEGORY_IMAGES,
    CATEGORY_IMAGES_SUCCESSFULL,
    CATEGORY_IMAGES_FAILED,
    ALL_BRANDS_BY_PRODUCT,
    ALL_BRANDS_BY_PRODUCT_SUCCESSFULL,
    ALL_BRANDS_BY_PRODUCT_FAILED,
    ALL_BMW_BRANDS,
    ALL_BMW_BRANDS_SUCCESSFULL,
    ALL_BMW_BRANDS_FAILED,
    SYNC_PRODUCT_ADMIN,
    SYNC_PRODUCT_ADMIN_SUCCESSFULL,
    SYNC_PRODUCT_ADMIN_FAILED,
    GET_THREASHOLD,
    GET_THREASHOLD_SUCCESSFULL,
    GET_THREASHOLD_FAILED,
    UPDATE_THRESHOLD,
    UPDATE_THRESHOLD_SUCCESSFULL,
    UPDATE_THRESHOLD_FAILED,
} from '../actions/products';



const initialState = {
    isLoading: false,
    allProducts: {},
    singleProductDetailData: {},
    infoAndEffectsData: [],

    //Manage Category
    allCategoryData: {},
    allBlazeCategoryData: {},
    categoryImages: [],
    allBrands: {},
    allBmwBrands: {},
    syncProductLoader: false,
    productThreshold: 0,
}

export default function product(state = { ...initialState }, action = {}) {
    switch (action.type) {

        //Get all products
        case GET_ALL_PRODUCTS: { }
            return { ...state, isLoading: true }

        case GET_ALL_PRODUCTS_SUCCESSFULL:
            return { ...state, isLoading: false, allProducts: action.data }

        case GET_ALL_PRODUCTS_FAILED:
            return { ...state, isLoading: false }

        //Get Single product Details
        case GET_SINGLE_PRODUCT_DETAILS:
            return { ...state, isLoading: true }

        case GET_SINGLE_PRODUCT_DETAILS_SUCCESSFULL:
            return { ...state, isLoading: false, singleProductDetailData: action.data }

        case GET_SINGLE_PRODUCT_DETAILS_FAILED:
            return { ...state, isLoading: false }

        //Get Info and Effect
        case GET_INFO_AND_EFFECT:
            return { ...state, isLoading: true }

        case GET_INFO_AND_EFFECT_SUCCESSFULL:
            return { ...state, isLoading: false, infoAndEffectsData: action.data }

        case GET_INFO_AND_EFFECT_FAILED:
            return { ...state, isLoading: false }

        //Update single product
        case UPDATE_SINGLE_PRODUCT:
            return { ...state, isLoading: true }

        case UPDATE_SINGLE_PRODUCT_SUCCESSFULL:
            return { ...state, isLoading: false }

        case UPDATE_SINGLE_PRODUCT_FAILED:
            return { ...state, isLoading: false }

        //Add category
        case ADD_CATEGORY:
            return { ...state, isLoading: true }

        case ADD_CATEGORY_SUCCESSFULL:
            return { ...state, isLoading: false }

        case ADD_CATEGORY_FAILED:
            return { ...state, isLoading: false }

        //get all category
        case GET_ALL_CATEGORY:
            return { ...state, isLoading: true }

        case GET_ALL_CATEGORY_SUCCESSFULL:
            return { ...state, isLoading: false, allCategoryData: action.data }

        case GET_ALL_CATEGORY_FAILED:
            return { ...state, isLoading: false }

        //update category 
        case UPDATE_CATEGORIES:
            return { ...state, isLoading: true }

        case UPDATE_CATEGORIES_SUCCESSFULL:
            return { ...state, isLoading: false }

        case UPDATE_CATEGORIES_FAILED:
            return { ...state, isLoading: false }

        //Delete category 
        case DELETE_CATEGORY:
            return { ...state, isLoading: true }

        case DELETE_CATEGORY_SUCCESSFULL:
            return { ...state, isLoading: false }

        case DELETE_CATEGORY_FAILED:
            return { ...state, isLoading: false }

        //get all Blaze category
        case GET_ALL_BLAZE_CATEGORY:
            return { ...state, isLoading: true }

        case GET_ALL_BLAZE_CATEGORY_SUCCESSFULL:
            return { ...state, isLoading: false, allBlazeCategoryData: action.data }

        case GET_ALL_BLAZE_CATEGORY_FAILED:
            return { ...state, isLoading: false }

        //get all category images
        case CATEGORY_IMAGES_SUCCESSFULL:
            return { ...state, isLoading: false, categoryImages: action.data }

        case CATEGORY_IMAGES_FAILED:
            return { ...state, isLoading: false, categoryImages: action.data }

        case ALL_BRANDS_BY_PRODUCT:
            return { ...state, isLoading: true }

        case ALL_BRANDS_BY_PRODUCT_SUCCESSFULL:
            return { ...state, isLoading: false, allBrands: action.data }

        case ALL_BRANDS_BY_PRODUCT_FAILED:
            return { ...state, isLoading: false }

        case ALL_BMW_BRANDS:
            return { ...state, isLoading: true }

        case ALL_BMW_BRANDS_SUCCESSFULL:
            return { ...state, isLoading: false, allBmwBrands: action.data }

        case ALL_BMW_BRANDS_FAILED:
            return { ...state, isLoading: false }

        case SYNC_PRODUCT_ADMIN:
            return { ...state, syncProductLoader: true }

        case SYNC_PRODUCT_ADMIN_SUCCESSFULL:
            return { ...state, syncProductLoader: false }

        case SYNC_PRODUCT_ADMIN_FAILED:
            return { ...state, syncProductLoader: false }

        //Get Threshold Details
        case GET_THREASHOLD:
            return { ...state, isLoading: true }

        case GET_THREASHOLD_SUCCESSFULL:
            return { ...state, isLoading: false, productThreshold: action.data }

        case GET_THREASHOLD_FAILED:
            return { ...state, isLoading: false }

        //Update Threshold

        case UPDATE_THRESHOLD:
            return { ...state, isLoading: true }

        case UPDATE_THRESHOLD_SUCCESSFULL:
            return { ...state, isLoading: false }

        case UPDATE_THRESHOLD_FAILED:
            return { ...state, isLoading: false }

        default:
            return state
    }
}

