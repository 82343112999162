import { isEmpty } from "lodash";
export function validateStrainData(data = {}) {
    let errors = {};

    if (!data.strainName) {
        errors.strainName = "Strain Name is required!";
    }
    if (!data.strainCategory) {
        errors.strainCategory = "Strain Category is required!";
    }
    if (!data.metaTitle) {
        errors.metaTitle = "Meta Title is required!";
    }

    // if (!data.description) {
    //     errors.description = "Description is required!";
    // }

    if (!data.metaDescription) {
        errors.metaDescription = "Meta Description is required!";
    }
    if (!data.image) {
        errors.image = "Image is required!";
    }

    // if (!data.createdBy) {
    //     errors.createdBy = "Created By is required!";
    // }

    return {
        isValid: isEmpty(errors),
        errors
    }
}

export function validateStrainDraftData(data = {}) {
    let errors = {};

    if (!data.strainCategory) {
        errors.strainCategory = "Strain Category is required!";
    }
    if (!data.strainName) {
        errors.strainName = "Strain Name is required!";
    }

    return {
        isValid: isEmpty(errors),
        errors
    }
}
