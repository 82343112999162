import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as commonActions from "../../../redux/actions/common"

import Dialog from './Dialog';
import { AddCategory, ZipcodeModal, AddBrandModal, AddSpecialProduct, EditPromotions, AddUserModal, AddTruckZipcode, AddEmployeeModal } from '../../../containers';
import CustomConfirmationModal from '../CustomConfirmationModal';

import ViewItems from '../../../containers/Orders/ViewItems'
import ThresholdModal
    from '../../../containers/ManageProducts/ThresholdModal';
import AddStrainModal from '../../../containers/ManageStrains/AddStrain';
import AddBannerModal from '../../../containers/ManageBanners/AddBanner';
import AddCategoryBannerModal from '../../../containers/ManageCategoriesBanners/AddCategoryBanner';

export default function CustomModal() {
    const dispatch = useDispatch();


    const { modal, modalData } = useSelector(state => state.common);

    const closeModal = () => {
        dispatch(commonActions.closeCustomModalDialog({}))
    }


    switch (modal) {
        case "AddCategory":
            return <RenderModalWithComponent component={AddCategory} customFunction={closeModal} />
        case "ZipCodeModal":
            return <RenderModalWithComponent component={ZipcodeModal} customFunction={closeModal} />
        case "AddBrandModal":
            return <RenderModalWithComponent component={AddBrandModal} customFunction={closeModal} />
        case "ConfirmationModal":
            return <RenderModalWithComponent component={CustomConfirmationModal} customFunction={closeModal} maxWidth="xs" />
        case "AddSpecialProduct":
            return <RenderModalWithComponent component={AddSpecialProduct} customFunction={closeModal} />
        case "EditPromotion":
            return <RenderModalWithComponent component={EditPromotions} customFunction={closeModal} />
        case "AddUserModal":
            return <RenderModalWithComponent component={AddUserModal} customFunction={closeModal} />
        case "AddTruckZipcode":
            return <RenderModalWithComponent component={AddTruckZipcode} customFunction={closeModal} />
        case "ViewItems":
            return <RenderModalWithComponent component={ViewItems} customFunction={closeModal} maxWidth="md" />
        case "ThresholdModal":
            return <RenderModalWithComponent component={ThresholdModal} modalData={modalData} customFunction={closeModal} maxWidth="sm" />
        case "AddEmployeModal":
            return <RenderModalWithComponent component={AddEmployeeModal} customFunction={closeModal} />
        case "AddStrainModal":
            return <RenderModalWithComponent maxWidth='lg' component={AddStrainModal} customFunction={closeModal} />
        case "AddBannerModal":
            return <RenderModalWithComponent maxWidth='md' component={AddBannerModal} customFunction={closeModal} />
        case "AddCategoryBannerModal":
            return <RenderModalWithComponent maxWidth='md' component={AddCategoryBannerModal} customFunction={closeModal} />
        default:
            return null
    }
}

const RenderModalWithComponent = ({ component: Component, maxWidth = "sm", customFunction, ...rest }) => {
    return (
        <>
            <Dialog fullWidth maxWidth={maxWidth} customFunction={customFunction}>
                <Component {...rest} closeModal={customFunction} />
            </Dialog>
        </>
    )
}

