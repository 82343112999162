import { apiGet, apiPost, apiDelete, apiPut } from '../../utils';

export function getStrainsAPI(data) {
    return apiGet('api/v1/strain?type=admin', data);
}

export function addOrUpdateStrainAPI(data, id = '') {
    if (id) {
        return apiPut(`api/v1/strain/update/${id}`, data);
    }
    return apiPost('api/v1/strain/create', data);
}

export function deleteStrainAPI(id) {
    return apiDelete(`api/v1/strain/delete/strain/${id}`)
}

export function getAllInfoEffectAPI() {
    return apiGet(`api/v1/admin/products/infoEffects`)
}

export function uploadImageAPI(data) {
    return apiPost(`api/v1/strain/upload/image`, data)
}

export function getFlavorsAPI(data) {
    return apiGet('api/v1/strain/get/all/flavors', data);
}

// Main Strain API
export function getMainStrainsAPI(data) {
    return apiGet('api/v1/strain/main/page', data);
}

export function updateMainStrainAPI(data) {
    return apiPost(`api/v1/strain/update/main/metas`, data);
}