import { toast } from 'react-toastify';
import { deleteBannerAPI, getBannerTimingAPI,getBannersListsAPI,updateBannerListAPI,updateBannerTimingAPI, updateFhlStatusAPI, getFhlStatusAPI } from '../api/banner';

export const GET_BANNER_TIMING = 'GET_BANNER_TIMING';
export const GET_BANNER_TIMING_SUCCESS = 'GET_BANNER_TIMING_SUCCESS';
export const GET_BANNER_TIMING_FAILURE = 'GET_BANNER_TIMING_FAILURE';

export function getBannerTiming(data) {
    return dispatch => {
        dispatch({
            type: GET_BANNER_TIMING
        })
        return new Promise((resolve, reject) => {
            getBannerTimingAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_BANNER_TIMING_SUCCESS,
                        data: res
                    })
                
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_BANNER_TIMING_FAILURE
                    })
                    return reject(err)
                })
        })
    }
}

export const UPDATE_BANNER_TIMING = 'UPDATE_BANNER_TIMING';
export const UPDATE_BANNER_TIMING_SUCCESS = 'UPDATE_BANNER_TIMING_SUCCESS';
export const UPDATE_BANNER_TIMING_FAILURE = 'UPDATE_BANNER_TIMING_FAILURE';

export function updateBannerTiming(data) {
    return dispatch => {
        dispatch({
            type: UPDATE_BANNER_TIMING
        })
        return new Promise((resolve, reject) => {
            updateBannerTimingAPI(data)
                .then((res) => {
                    dispatch({
                        type: UPDATE_BANNER_TIMING_SUCCESS,
                        data: res
                    })
                    toast.success(res.message)
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_BANNER_TIMING_FAILURE
                    })
                    return reject(err)
                })
        })
    }
}

export const REQUEST_BANNERS_LIST = 'REQUEST_BANNERS_LIST';
export const REQUEST_BANNERS_LIST_SUCCESSFULL = 'REQUEST_BANNERS_LIST_SUCCESSFULL';
export const  REQUEST_BANNERS_LIST_FAILED = ' REQUEST_BANNERS_LIST_FAILED';

export function getBanners(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_BANNERS_LIST
        })
        return new Promise((resolve, reject) => {
            getBannersListsAPI(data)
                .then((res) => {
                    dispatch({
                        type: REQUEST_BANNERS_LIST_SUCCESSFULL,
                        data:res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: REQUEST_BANNERS_LIST_FAILED
                    })
                    return reject(err)
                })
        })
    }
}


export const UPDATE_BANNER_LIST = 'UPDATE_BANNER_LIST';
export const UPDATE_BANNER_LIST_SUCCESSFULL = 'UPDATE_BANNER_LIST_SUCCESSFULL';
export const  UPDATE_BANNER_LIST_FAILED = ' UPDATE_BANNER_LIST_FAILED';

export function updateBanner(data,id) {
    return dispatch => {
        dispatch({
            type: UPDATE_BANNER_LIST
        })
        return new Promise((resolve, reject) => {
            updateBannerListAPI(data,id)
                .then((res) => {
                    toast.success(res.message)
                    dispatch({
                        type: UPDATE_BANNER_LIST_SUCCESSFULL
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_BANNER_LIST_FAILED
                    })
                    return reject(err)
                })
        })
    }
}


export const DELETE_BANNER = 'DELETE_BANNER';
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS'
export const DELETE_BANNER_FAILED = 'DELETE_BANNER_FAILED'

export function deleteBanner(id) {
    return dispatch => {
        dispatch({ type: DELETE_BANNER })
        return new Promise((resolve, reject) => {
            deleteBannerAPI(id).then(res => {
                dispatch({ type: DELETE_BANNER_SUCCESS, payload: res })
                toast.success(res.message)
                resolve(res)
            }).catch((err) => {
                dispatch({ type: DELETE_BANNER_FAILED })
                reject(err)
            })
        })
    }
}


////////////////////// FHL STATUS ////////////////////

export const UPDATE_FHL_STATUS = 'UPDATE_FHL_STATUS';
export const UPDATE_FHL_STATUS_SUCCESSFULL = 'UPDATE_FHL_STATUS_SUCCESSFULL';
export const UPDATE_FHL_STATUS_FAILED = 'UPDATE_FHL_STATUS_FAILED';

export function updateFhlStatus(id, data) {
    return dispatch => {
        dispatch({
            type: UPDATE_FHL_STATUS
        })
        return new Promise((resolve, reject) => {
            updateFhlStatusAPI(id, data)
                .then((res) => {
                    dispatch({
                        type: UPDATE_FHL_STATUS_SUCCESSFULL,
                    })
                    return resolve(res)
                })
                .catch(err => {
                    toast.error(err)
                    dispatch({
                        type: UPDATE_FHL_STATUS_FAILED
                    })
                    return reject(err)
                })
        })
    }
}

export const GET_FHL_STATUS = 'GET_FHL_STATUS';
export const GET_FHL_STATUS_SUCCESS = 'GET_FHL_STATUS_SUCCESS';
export const GET_FHL_STATUS_FAILURE = 'GET_FHL_STATUS_FAILURE';

export function getFhlStatus(data) {
    return dispatch => {
        dispatch({
            type: GET_FHL_STATUS
        })
        return new Promise((resolve, reject) => {
            getFhlStatusAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_FHL_STATUS_SUCCESS,
                        data: res
                    })
                
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_FHL_STATUS_FAILURE
                    })
                    return reject(err)
                })
        })
    }
}
