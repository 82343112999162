
import { isEmpty } from "lodash";

export function validateMainStrainData(data = {}) {
    let errors = {};

    if (!data.metaTitle) {
        errors.metaTitle = "Meta Title is required!";
    }

    if (!data.metaDescription) {
        errors.metaDescription = "Meta Description is required!";
    }
    if (!data.image) {
        errors.image = "Image is required!";
    }

    return {
        isValid: isEmpty(errors),
        errors
    }
}