import { isEmpty } from "lodash";
import { validURL } from "../../utils";

export function validateBannerData(data = {}) {
    let errors = {};

    if (!data.banner_position) {
        errors.banner_position = "Banners Position is required!";
    }
    
    if (!data?.desktop_image) {
        errors.desktop_image = "This  is required";
    }

    if (!data?.mobile_image) {
        errors.mobile_image = "This is required";
    }
    if (!data?.url) {
        errors.url = "URL is required";
    }

    if(data.url && !validURL(data.url)){
        errors.url = "Please add valid url";
    }

    if (!data?.media_type) {
        errors.media_type = "Media Type is required";
    }

    return {
        isValid: isEmpty(errors),
        errors
    }
}