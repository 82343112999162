import React, { useState, useEffect, useMemo } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom"
import { Button, Typography, CircularProgress, Grid, FormHelperText, FormControl, FormGroup, Container, Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash"
import CustomInput from '../../components/common/CustomInput';
import * as actions from "../../redux/actions/strains"
import * as commonActions from "../../redux/actions/common"
import { validateEmployeeData } from '../../utilities/validations/employee';
import CommonEditor from '../../components/common/CommonEditor';
import { validateStrainData, validateStrainDraftData } from '../../utilities/validations/strain';
import CustomCheckbox from '../../components/common/CustomCheckbox';
import CustomSelect from '../../components/common/CustomSelect';
import CustomMultiSelect from '../../components/common/CustomMultiSelect';


const initialEmployee = {}

function AddStrainModal(props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch()

    const { modalData } = useSelector(state => state.common)
    const { isLoading = false, infoEffects, allFlavorsData, allStrains } = useSelector(state => state.strains)
    const { title = "Add", data: editData = {} } = modalData
    const [strainData, setStrainData] = useState({ ...initialEmployee })
    const [errors, setErrors] = useState({ ...initialEmployee })
    const [selectedFlavors, setSelectedFlavors] = useState([])
    const [pagination, setPagination] = useState({
        limit: 200,
        skip: 0,
    });
    const isEditdata = useMemo(() => {
        return !_.isEmpty(editData)
    }, [editData])
    const infoEffectArr = useMemo(() => {
        return infoEffects?.find((effect => effect?.strainType === strainData.strainCategory))?.infoEffects || []
    }, [strainData.strainCategory, infoEffects])
    // let infoEffectObj = infoAndEffectsData?.length && infoAndEffectsData?.filter((v) => v?.strainType === (flowerType?.toLowerCase()))[0]
    // let infoEffectArr = (infoEffectObj?.infoEffects) || [];

    const capitalizeString = (str) => {
        return (str?.slice(0, 1)?.toUpperCase() + str?.slice(1)?.toLowerCase());
    };


    useEffect(() => {
        if (editData?.flavors?.length) {
            setSelectedFlavors([...selectedFlavors, ...editData.flavors?.map((flavour => {
                return { name: flavour }
            }))])
        }
    }, [editData])

    const allFlavorsDataArr = useMemo(() => {
        return allFlavorsData?.length > 0 ? allFlavorsData?.map((flavour => {
            return { name: flavour.flavorType }
        })) : []
    }, [allFlavorsData])

    const onChangeCheckbox = (e, name) => {
        e.preventDefault();
        let updatedEffects = [...(strainData?.infoEffects || [])]
        let alreadyExist = updatedEffects.findIndex(v => v === name)
        if (alreadyExist === -1) {
            updatedEffects.push(name)
        } else {
            updatedEffects.splice(alreadyExist, 1)
        }
        setStrainData({ ...strainData, infoEffects: updatedEffects })
    }

    useEffect(() => {
        if (isEditdata) {
            setStrainData({ ...editData, flavors: editData.flavors?.join(',') });
            
        }
           
    }, [isEditdata, editData]);

    const onChangehandler = (e) => {
        const { name, value } = e.target;
        setStrainData({ ...strainData, [name]: value });
    };

    const onEditorChangehandler = (value, name) => {
        setStrainData({ ...strainData, [name]: value });
    };

    const addUser = (submitType) => {
        const { errors: validationErrors, isValid: isDataValid } =  submitType === 'save_as_draft' ? validateStrainDraftData(strainData) : validateStrainData(strainData);
        if (!isDataValid) {
            setErrors(validationErrors);
            return;
        }
        setErrors({})
        const userForPayload = { ...strainData, status: submitType === 'save_as_draft' ? false : true, flavors: selectedFlavors?.map((flavour => Object.values(flavour)[0])) };
        dispatch(actions.addOrUpdateStrain(userForPayload, (strainData._id || ''), submitType)).then(res => {
            dispatch(actions.getAllStrains(pagination));
            dispatch(commonActions.closeCustomModalDialog());
        });
    };

    const imageChangeHandler = (e) => {
        let file = e.target.files[0]
        const formData = new FormData();
        formData.append('filePath', file)
        dispatch(actions.uploadStrainImage(formData)).then((res) => {
            // console.log("res",res)
            setStrainData({ ...strainData, image: res?.fileUrl })
        });
    }

    // useEffect(() => {
    //     dispatch(actions.getAllInfoEffect())
    //     dispatch(actions.getAllFlavors())
    // }, [])

    const handleChangeFlavor = (e, val, index) => {
        setSelectedFlavors(val)
    };

    return (
        <Grid container spacing={4} component="main" className={`${classes.root} add-zipcode-modal-container`}>
            <Grid item xs={12} className="pt-2">
                <Typography variant="h4" className="d-flex jc-ctr">{title} Strain</Typography>
            </Grid>

            <Grid item xs={12} className="add-category-input">
                <Grid item xs={12}>
                    <CustomSelect
                        name="strainCategory"
                        value={strainData.strainCategory || ""}
                        menuItem={infoEffects?.map((effect => ({ title: effect.strainType, value: effect.strainType }))) || []}
                        onChange={onChangehandler}
                        label="Strain Category"
                        className="w-100"
                        margin="normal"
                        />
                        {errors.strainCategory && <p className='error' >{errors.strainCategory}</p>}
                </Grid>
                <Grid item xs={12}>
                    <CustomInput
                        name="strainName"
                        value={strainData.strainName || ""}
                        onChange={onChangehandler}
                        label="Strain Name"
                        className="w-100"
                        margin="normal"
                        error={errors.strainName}
                    />
                </Grid>
                <Grid item xs={12} >
                    <CustomInput
                        name="metaTitle"
                        value={strainData.metaTitle || ""}
                        onChange={onChangehandler}
                        label="Meta Title"
                        className="w-100"
                        margin="normal"
                        error={errors.metaTitle}
                    />
                </Grid>
                <Grid item xs={12} >
                    <CustomInput
                        name="metaDescription"
                        value={strainData.metaDescription || ""}
                        onChange={onChangehandler}
                        label="Meta Description"
                        className="w-100"
                        margin="normal"
                        error={errors.metaDescription}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormHelperText style={{ fontSize: '14px' }}>Description</FormHelperText>
                        <CommonEditor onChange={onEditorChangehandler} id={"description"} value={strainData.description} />
                        {/* {
                            errors.description && <FormHelperText>{errors.description}</FormHelperText>
                        } */}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormHelperText style={{ fontSize: '14px' }}>Title</FormHelperText>
                        <CommonEditor onChange={onEditorChangehandler} id={"title"} value={strainData.title} />
                        {/* {
                            errors.title && <FormHelperText>{errors.title}</FormHelperText>
                        } */}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    {
                        infoEffectArr?.length ?
                            <FormGroup className="effects-listing" >
                                <Grid container>
                                    {
                                        infoEffectArr?.length ? infoEffectArr?.map((item, index) => {
                                            const { title } = item;
                                            const capatlizedTitle = capitalizeString(title);
                                            let value = strainData?.infoEffects?.includes(title);
                                            const selectedImage = []?.filter(data => data.title === title);
                                            return <Grid item md={3} className='innerGridstyle'  >
                                                <div key={index} className={`effect-chkbox ${value ? "active" : ""}`} onClick={(e) => onChangeCheckbox(e, title)}
                                                >
                                                    <img src={`${process.env.REACT_APP_AMZAON_S3_URL}infoEffects${item.url}` || "/images/ProductEffectImage/default.svg"} alt={item.url || "default_image"} />
                                                    <CustomCheckbox
                                                        label={capatlizedTitle}
                                                        value={value || false}

                                                    />
                                                </div>
                                            </Grid>
                                        })
                                            :
                                            null
                                    }
                                </Grid>
                            </FormGroup>
                            :
                            null
                    }
                </Grid>
                <hr></hr>
                <Grid item xs={12}>
                    <Grid container className="mb-2">
                        <Grid item xs={3}>
                            <CustomCheckbox
                                label={"Best Strain"}
                                value={strainData?.isBestStrain || false}
                                onChange={e => onEditorChangehandler(e.target.checked, "isBestStrain")}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CustomCheckbox
                                label={"Popular Strain"}
                                value={strainData.isPopularStrain || false}
                                onChange={e => onEditorChangehandler(e.target.checked, "isPopularStrain")}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <CustomMultiSelect
                        label="Flavors"
                        name="name"
                        fullWidth
                        options={allFlavorsDataArr || []}
                        handleChange={(e, val) =>
                            handleChangeFlavor(e, val)
                        }
                        value={selectedFlavors || []}
                        className="w-50"
                    />
                </Grid>
                <Grid item xs={12} >
                    <CustomInput
                        name="createdBy"
                        value={strainData.createdBy || ""}
                        onChange={onChangehandler}
                        label="Created By"
                        className="w-100"
                        margin="normal"
                        error={errors.createdBy}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormHelperText style={{ fontSize: '14px' }}>Title 2</FormHelperText>
                        <CommonEditor onChange={onEditorChangehandler} id={"title2"} value={strainData.title2} />
                        {/* {
                            errors.description && <FormHelperText>{errors.description}</FormHelperText>
                        } */}
                    </FormControl>
                </Grid>
                <Grid item xs={12} >
                    <input type='file' name='filePath' onChange={imageChangeHandler} />
                    {
                        strainData?.image && <img style={{ height: 100, width: 200 }} src={strainData?.image} />
                    }
                    {
                        errors.image && <FormHelperText><div className='error' >{errors.image}</div></FormHelperText>
                    }
                </Grid>

                <Grid item xs={12} >
                    <Box my={4} className="d-flex jc-ctr add-button">
                        <Grid container spacing={4}>
                            <Grid item xs={6} lg={6}>
                                <Button onClick={() => addUser('save')} variant="contained" color="primary">{isLoading ? <CircularProgress size={22} style={{ color: "white" }} /> : 'Publish'}</Button>
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <Box display='flex' justifyContent='flex-end' alignItems='center'>
                                    <Button onClick={() => addUser('save_as_draft')} variant="contained" style={{ background: '#ff0000ad', color: 'white', display: 'flex', justifyContent: 'flex-end' }}>{isLoading ? <CircularProgress size={22} style={{ color: "white" }} /> : 'Save as Draft'}</Button>

                                </Box>

                            </Grid>
                        </Grid>
                    </Box>

                </Grid>
            </Grid>

        </Grid >
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));


export default AddStrainModal