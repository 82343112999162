import { GET_ALL_EMPLOYEE,GET_ALL_EMPLOYEE_SUCCESS,GET_ALL_EMPLOYEE_FAILED,
         ADD_EMPLOYEE,ADD_EMPLOYEE_SUCCESS,ADD_EMPLOYEE_FAILED,
        DELETE_EMPLOYEE,DELETE_EMPLOYEE_SUCCESS,DELETE_EMPLOYEE_FAILED } from "../actions/employee";  


        const initialState = {
            allEmployees: [],
            isLoading: false,
           
        }

        export default function employee(state = { ...initialState }, action = {}) {
            switch (action.type) {
                case GET_ALL_EMPLOYEE: {
                    return { ...state, isLoading: true }
                }
                case GET_ALL_EMPLOYEE_SUCCESS: {
                    const { employees = [], total = 0 } = action.payload || {}
                    return { ...state, isLoading: false, allEmployees: employees, }
                }
                case GET_ALL_EMPLOYEE_FAILED: {
                    return { ...state, isLoading: false }
                }
                case ADD_EMPLOYEE: {
                    return { ...state, isLoading: true }
                }
                case ADD_EMPLOYEE_SUCCESS: {
                    return { ...state, isLoading: false }
                }
                case ADD_EMPLOYEE_FAILED: {
                    return { ...state, isLoading: false }
                }
                case DELETE_EMPLOYEE: {
                    return { ...state, isLoading: true }
                }
                case DELETE_EMPLOYEE_SUCCESS: {
                    return { ...state, isLoading: false }
                }
                case DELETE_EMPLOYEE_FAILED: {
                    return { ...state, isLoading: false }
                }
                default: {
                    return state
                }
            }
        }
        